<template>
  <section>
    <div class="l-home__games-header">
      <div v-if="!isJackbotPage">
        <Flicking
            ref="flicking"
            style="width: 100%; margin-bottom: 30px;"
            :plugins="plugins"
            :options="{
            circular: true,
          }"
        >
          <div key="win_topps" class="panel">
            <img :src="require('../../assets/images/adv/banner_win_topps.webp')"
                 alt=""
                 class="main-banner"
                 @click="clickTopps"
            />
          </div>
          <div key="Telegram" class="panel">
            <img :src="require('../../assets/images/launch_tg.webp')"
                 style="border-radius: 30px"
                 alt=""
                 class="main-banner"
                 @click="openTelegramBot"
            />
          </div>
          <div key="Slot Battle" class="panel">
            <img :src="require('../../assets/images/banner_slotbattle.webp')"
                 style="border-radius: 30px"
                 alt=""
                 class="main-banner"
                 @click="setSelectedGamesPage('battle')"
            />
          </div>
          <div key="Mayan Treasure" class="panel">
            <img :src="require('../../assets/images/'+(isMobile?'mobile/mayanTreasure-banner.webp':'mayanTreasure-banner.webp'))"
                 style="border-radius: 30px"
                 alt=""
                 class="main-banner"
                 @click="page='instant';openGame('mayan')"
            />
          </div>
          <div key="Funko NFTs prize" class="panel">
            <img :src="require('../../assets/images/'+(isMobile?'mobile/banner-Funko.webp':'banner-Funko.webp'))"
                 alt=""
                 class="main-banner"
            />
          </div>
          <div key="Spincity Tycoon" class="panel">
            <img :src="require('../../assets/images/'+(isMobile?'mobile/spincity-banner.webp':'spincity-banner.webp'))"
                 alt=""
                 class="main-banner"
                 @click="openSpincityBlog"
            />
          </div>

          <template #viewport>
            <div class="flicking-pagination"></div>
          </template>
        </Flicking>
      </div>

      <div v-else style="height: 150px;"></div>
      <h2>
        {{ $t("general.ourgames") }}
      </h2>
      <div v-if="!isJackbotPage" class="l-home__games-categories">
        <div class="l-home__games-categories-button" @click="setSelectedGamesPage('slot')">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/icon_slot.webp':'icon_slot.webp'))" alt="" />
          <p :class="{ 'l-home__games-categories-button-selected':page==='slot',
                       'l-home__games-categories-button-unselected':page!=='slot' }"
          >
            Slots
          </p>
        </div>
        <div class="l-home__games-categories-button" @click="setSelectedGamesPage('scratch')">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/icon_scratch.webp':'icon_scratch.webp'))" alt="" />
          <div style="position: relative">
            <p :class="{ 'l-home__games-categories-button-selected':page==='scratch' ,
                         'l-home__games-categories-button-unselected':page!=='scratch' }"
            >
              Scratch
            </p>
          </div>
        </div>
        <div class="l-home__games-categories-button" @click="setSelectedGamesPage('crash')">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/icon_crash.webp':'icon_crash.webp'))" alt="" />
          <div style="position: relative">
            <p :class="[page==='crash'?'l-home__games-categories-button-selected':'l-home__games-categories-button-unselected']">
              Crash
            </p>
            <!--p class="new"
               :style="page==='crash'?'opacity:0':''"
            >
              New
            </p-->
          </div>
        </div>
        <div class="l-home__games-categories-button" @click="setSelectedGamesPage('instant')">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/icon_instaGame.webp':'icon_instaGame.webp'))" alt="" />
          <div style="position: relative">
            <p :class="[page==='instant'?'l-home__games-categories-button-selected':'l-home__games-categories-button-unselected']">
              Instant Games
            </p>
            <!--p class="new"
               :style="page==='instant'?'opacity:0':''"
            >
              New
            </p-->
          </div>
        </div>
        <!--Slot battle
        @click="isTest?setSelectedGamesPage('battle'):null
        -->
        <div class="l-home__games-categories-button" @click="setSelectedGamesPage('battle')">
          <img :src="img_slot_battle" alt="" />
          <div style="position: relative">
            <p :class="[page==='battle'?'l-home__games-categories-button-selected':'l-home__games-categories-button-unselected']">
              Slot battle
            </p>
            <p class="new"
               :style="page==='battle'?'opacity:0':''"
            >
              New
            </p>
          </div>
        </div>
        <div class="l-home__games-categories-button" @click="isTest?setSelectedGamesPage('spincity'):null">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/spincity_icon.webp':'spincity_icon.webp'))" alt="" />
          <div style="position: relative">
            <p v-if="isTest" :class="[page==='spincity'?'l-home__games-categories-button-selected':'l-home__games-categories-button-unselected']">
              Spincity Tycoon
            </p>
            <p v-else>
              Spincity Tycoon
            </p>
            <p v-if="isTest"
               class="new"
               :style="page==='spincity'?'opacity:0':''"
            >
              New
            </p>
            <p v-else
               class="soon"
            >
              Soon
            </p>
            <p class="nft">
              NFT
            </p>
          </div>
        </div>
        <!--Poker-->
        <div class="l-home__games-categories-button" @click="isTest?setSelectedGamesPage('poker'):null">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/icon_Pocker.webp':'icon_Pocker.webp'))" alt="" />
          <div style="position: relative">
            <p :class="[page==='poker'?'l-home__games-categories-button-selected':'l-home__games-categories-button-unselected']">
              Poker
            </p>
            <p class="soon"
               :style="page==='poker'?'opacity:0':''"
            >
              Soon
            </p>
          </div>
        </div>

        <!-- Casino -->
        <div class="l-home__games-categories-button" @click="isTest?setSelectedGamesPage('casino'):null">
          <img :src="require('../../assets/images/'+(isMobile?'mobile/icon_Casino.webp':'icon_Casino.webp'))" alt="" />
          <div style="position: relative">
            <p :class="{ 'l-home__games-categories-button-selected':page==='casino' ,
                         'l-home__games-categories-button-unselected':page!=='casino' }"
            >
              Casino
            </p>
            <p v-if="isTest"
               class="new"
               :style="page==='casino'?'opacity:0':''"
            >
              New
            </p>
            <p v-else
               class="soon"
            >
              Soon
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="l-home__games-grid">
      <div
          v-for="slotGame in getGames()"
          :key="slotGame.name"
          :style="isJackbotPage&&!isMobile?'flex: 1 1 18%;width: 16%; max-width: 16%;':
          page==='crash'?'':''"
          :class="{ 'l-home__games-grid-item-crash':page==='crash'||page==='spincity'||page==='casino' ,
                  'l-home__games-grid-item':page!=='crash'&&page!=='spincity'&&page!=='casino' }"

          @click="openGame(slotGame.name)"
      >
        <div v-if="$store.getters.getNumScratchCards[slotGame.name]"
             class="notifications-count-badge"
             style="transform: scale(1.2); top: -6px; right: 0;"
        >
          {{ $store.getters.getNumScratchCards[slotGame.name] }}
        </div>
        <app-card
            :img-path="slotGame.image"
            :game="slotGame.name"
            :title="slotGame.title"
            :description="$t('general.' + slotGame.description)"
            :cost="slotGame.cardPrice"
            :maxwin="slotGame.diamondWin"
            wrapper-classes="c-card--game"
            :is-new-game="slotGame.isNew"
            :is-cascading="slotGame.isCascading"
            :is-soon="slotGame.isSoon"
            :is-locked="isGameLocked(slotGame.name)"
            :is-unlock-price="$store.getters.getGamesGemUnlockPrice[slotGame.name]"
            :page="page"
        />
      </div>
    </div>

    <!--unlock game popup--><transition name="slide-fade-dropdown">
    <div v-if="unlockGamePopup"
         class="c-header-profile-wrap"
    >
      <div class="c-header-burn-conf">
        <div class="c-header-ticket-conf-title">
          {{ notEnoughGems?'You don\'t have enough GEMS':$t("general.unlock") }}
          <span v-if="!notEnoughGems"><br />{{ games[clickedGame].title }}</span>
        </div>
        <p v-if="!notEnoughGems" class="c-header-ticket-conf-text">
          for <br />
          <span v-if="$store.getters.getGamesGemUnlockDiscount[clickedGame]>0" style="text-decoration: line-through">
              {{ $filters.formatNumber( $store.getters.getGamesGemUnlockPrice[clickedGame] ) }} GEMS
            </span>
          <br v-if="$store.getters.getGamesGemUnlockDiscount[clickedGame]>0" />
          <span style="font-family: 'Rubik',sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 32px;
                                    line-height: 115%;"
          >

              {{ $filters.formatNumber( gameUnlockPriceWithDiscount(clickedGame) ) }} GEMS</span>
        </p>
        <div class="c-header-ticket-conf-btn" @click="unlockGame(clickedGame)">
          <img :src="require('../../assets/svg-icons/icon_confirm.svg')"
               style="fill: none"
               alt=""/>
          {{ notEnoughGems?"return":$t("general.confirm") }}
        </div>
        <div @click="unlockGamePopup=false">
          <img :src="require('../../assets/svg-icons/icon_close.svg')"
               class="c-header-profile-close"
               alt=""/>
        </div>
        <svg
            fill="none"

            height="305"
            style="position: absolute;"
            viewBox="0 0 324 305"
            width="324"
            xmlns="http://www.w3.org/2000/svg"
        >
          <g
              opacity="0.25"
          >
            <mask
                id="mask0_3071_26213"
                height="324"
                maskUnits="userSpaceOnUse"
                style="mask-type:alpha"
                width="324"
                x="0"
                y="0"
            >
              <circle
                  cx="162"
                  cy="162"
                  fill="url(#paint0_radial_3071_26213)"
                  r="162"
              />
            </mask>
            <g
                mask="url(#mask0_3071_26213)"
            >
              <path

                  d="M149.294 163.211L-108.482 176.136L-108.482 202.306L149.441 163.321L149.955 163.247L412.461 175.217L412.461 148.986L149.306 162.831L149.294 163.211Z"

                  fill="#FFE500"
              />
              <path

                  d="M149.686 161.092L149.625 161.19L-108.482 -79.716L-108.482 -34.8747L149.539 161.337L149.38 161.631L-108.482 3.59571L-108.482 37.5452L149.306 161.765L149.22 161.912L-108.482 68.3338L-108.482 96.9048L149.147 162.047L149.049 162.207L149.159 162.047L149.257 162.072L149.11 162.243L-108.482 124.018L-108.482 150.175L149.318 162.427L149.269 162.464L149.331 162.427L149.404 162.427L149.282 162.488L149.441 162.427L149.502 162.427L149.269 162.488L149.625 162.427L149.845 162.439L149.833 162.513L149.845 162.452L149.955 162.55L149.955 162.464L150.115 162.525L150.102 162.476L150.678 162.501L150.09 162.415L150.078 162.378L150.25 162.354L150.666 162.464L150.335 162.341L150.409 162.329L150.678 162.464L150.458 162.329L150.605 162.305L150.642 162.366L150.617 162.305L150.74 162.28L150.789 162.317L150.74 162.28L412.461 122.315L412.461 94.7486L150.838 162.096L150.74 161.962L412.461 65.6385L412.461 34.2986L150.642 161.839L150.544 161.692L412.461 -0.349327L412.461 -39.5426L150.458 161.569L150.225 161.239L412.461 -85.3763L412.461 -93.6952C411.162 -95.0061 409.839 -96.3048 408.528 -97.6035L374.701 -97.6035L150.151 161.067L150.274 160.491L332.163 -97.6035L295.102 -97.6035L150.409 159.793L262.034 -97.6035L231.613 -97.6035L150.972 157.036L203.238 -97.6035L175.966 -97.6035L150.127 159.695L149.968 160.21L149.453 -97.6035L122.855 -97.6035L149.735 160.21L149.588 159.866L95.5945 -97.6035L67.0112 -97.6035L148.51 157.428L36.6637 -97.6035L3.38803 -97.6035L149.331 159.977L149.331 159.989L-33.7715 -97.6035L-76.6157 -97.6035L149.539 160.651L149.686 161.092Z"

                  fill="#FFE500"
              />
              <path

                  d="M149.247 163.763L149.198 163.776L149.076 163.616L149.174 163.788L-108.48 229.518L-108.48 258.151L149.26 163.923L149.309 164.021L-108.48 288.976L-108.48 322.987L149.382 164.155L149.456 164.266L149.443 163.996L149.529 164.082L149.578 164.045L149.48 163.947L149.443 163.959L149.394 163.874L149.443 163.849L149.443 163.714L149.272 163.763L149.1 163.592L149.247 163.763Z"

                  fill="#FFE500"
              />
              <path

                  d="M4.20916 423.34L37.3868 423.34L149.074 167.646L67.7221 423.34L96.2686 423.34L149.466 167.953L149.453 166.74L149.662 166.274L149.515 167.732L149.956 165.6L150.066 165.355L150.311 165.784L177.632 423.327L205.003 423.327L151.046 168.002L233.439 423.327L263.983 423.327L151.61 168.051L297.221 423.34L334.467 423.34L150.581 165.735L150.434 165.122L377.409 423.34L408.651 423.34C409.925 422.09 411.199 420.84 412.461 419.566L412.461 408.258L150.36 164.779L150.348 164.718L150.458 164.461L412.461 362.853L412.461 324.027L150.532 164.314L150.654 164.032L412.461 289.575L412.461 258.382L150.728 163.897L150.789 163.799L412.461 229.37L412.461 201.902L150.899 163.664L150.924 163.628L150.899 163.664L150.826 163.652L150.85 163.591L150.813 163.652L150.654 163.628L150.789 163.493L150.63 163.615L150.397 163.579L150.715 163.383L150.593 163.419L150.703 163.346L150.421 163.456L150.434 163.407L150.115 163.542L150.066 163.297L150.09 163.542L149.956 163.517L149.931 163.579L149.943 163.579L149.943 163.603L149.894 163.591L149.796 163.615L149.919 163.677L149.943 163.664L149.943 163.677L149.98 163.689L149.931 163.811L150.066 163.726L150.139 163.971L150.09 164.007L149.919 163.909L149.907 164.007L150.005 164.081L149.907 164.154L149.894 164.13L149.833 164.216L149.858 164.277L149.809 164.228L149.735 164.289L149.821 164.387L149.87 164.338L149.858 164.436L149.98 164.681L149.907 164.755L149.821 164.645L149.796 164.865L149.76 165.22L149.87 165.061L149.453 166.384L149.453 166.004L4.20916 423.34Z"

                  fill="#FFE500"
              />
              <path
                  d="M151.305 423.339L149.479 168.16L123.407 423.339L151.305 423.339Z"
                  fill="#FFE500"
              />
              <path
                  d="M-108.482 406.554L149.453 164.742L149.453 164.521L-108.482 361.492L-108.482 406.554Z"
                  fill="#FFE500"
              />
              <path
                  d="M-32.8893 423.34L149.453 165.699L149.453 165.307L-75.6356 423.34L-32.8893 423.34Z"
                  fill="#FFE500"
              />
            </g>
          </g>
          <defs>
            <radialGradient
                id="paint0_radial_3071_26213"
                cx="0"
                cy="0"

                gradientTransform="translate(162 162) rotate(90) scale(139.776)"
                gradientUnits="userSpaceOnUse"

                r="1"
            >
              <stop
                  stop-color="#D9D9D9"
              />
              <stop
                  offset="1"
                  stop-color="#D9D9D9"
                  stop-opacity="0"
              />
            </radialGradient>
          </defs>
        </svg>
        <svg
            fill="none"
            height="52"
            style="position:absolute;top: 0;left: 60px"
            viewBox="0 0 88 52"
            width="88"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M50.9477 33.9598C48.0304 34.7496 11.0836 14.1234 10.0858 11.9967C9.81576 11.4195 10.0871 9.83166 10.6412 7.81892C10.6376 7.8071 10.6376 7.8071 10.6376 7.8071C10.7133 7.55025 10.7854 7.28159 10.8692 7.00925C10.8692 7.00925 10.8774 6.99376 10.8855 6.97826C12.4536 1.72112 15.6196 -5.86016 16.3458 -6.5265C16.8887 -7.03217 30.842 -15.4377 36.2321 -17.1126C41.445 -18.7323 56.2376 -20.14 57.4621 -20.2872C59.2908 -20.4925 67.2183 -14.5435 71.415 -11.0906L71.4305 -11.0824C71.777 -10.8013 72.0927 -10.5364 72.3773 -10.2878L72.381 -10.276C73.2585 -9.53766 73.8852 -8.98062 74.0952 -8.72183C75.2391 -7.41816 53.865 33.17 50.9477 33.9598Z"
              fill="url(#paint0_linear_3071_26356)"
          />
          <path
              d="M12.2327 10.6031C12.7057 13.5022 35.7179 29.2034 49.4261 26.2661C56.4729 24.7505 57.8895 12.7485 59.0941 8.03201C60.3374 3.14797 42.4245 7.61212 38.5638 8.20249C34.6796 8.8002 39.6016 16.2144 41.1054 18.6765C42.5636 21.075 48.3822 20.654 49.7448 20.0751C51.111 19.508 53.2046 9.81014 49.6699 9.78077C46.0997 9.76243 46.1281 14.1087 43.9952 12.5421C41.8276 10.9473 51.856 5.887 54.5982 9.24752C57.2563 12.5045 54.7209 20.0713 47.1469 21.5174C39.522 22.9663 33.4513 17.1533 35.393 6.46577C37.4904 -5.13862 52.1835 2.89476 60.2101 5.15772C67.9524 7.34047 69.345 -8.076 67.4642 -12.5856C65.5128 -17.2806 50.4781 -20.4897 36.7898 -16.6124C23.0698 -12.7123 10.0839 -2.61123 12.2327 10.6031Z"
              fill="#B3B0FF"
              opacity="0.2"
          />
          <path
              d="M16.3428 -6.52598C14.3217 -5.14622 27.6042 -3.73867 29.4809 -3.99775C31.3458 -4.25317 47.6015 -9.04491 50.153 -10.1358C52.7163 -11.2304 58.454 -19.5459 58.1811 -20.174C57.9118 -20.7903 39.3585 -18.5121 35.8308 -16.9753C32.3268 -15.4459 17.8915 -7.59047 16.3428 -6.52598Z"
              fill="white"
          />
          <path
              d="M10.8304 11.5174C11.4713 12.9126 27.9001 16.1033 28.847 15.9387C29.7939 15.7741 30.4227 -4.01939 29.4708 -3.99583C28.519 -3.97226 17.7134 -5.50133 16.5476 -5.16503C15.3782 -4.84055 9.48455 8.60444 10.8304 11.5174Z"
              fill="#8DE1FF"
          />
          <path
              d="M30.544 15.6864C28.9136 16.1541 30.9211 -2.33095 31.4021 -3.45255C31.895 -4.57782 49.1734 -9.79101 50.7759 -9.47237C52.3667 -9.15006 60.8599 3.58357 61.0799 4.50029C61.3036 5.42884 39.9447 12.9858 30.544 15.6864Z"
              fill="#A69CE7"
              opacity="0.2"
          />
          <path
              d="M74.089 -8.73406C74.9168 -5.56937 63.1031 3.8823 62.0984 4.19449C61.0937 4.50668 52.4492 -9.63165 52.2417 -10.5912C52.0307 -11.5625 57.8685 -17.8871 59.1026 -18.5039C60.3249 -19.117 74.089 -8.73406 74.089 -8.73406Z"
              fill="#635EC0"
              opacity="0.4"
          />
          <path
              d="M30.5416 15.6839C28.72 16.1203 49.0731 33.1921 50.5367 33.6058C51.9885 34.0231 61.7536 7.42447 61.4192 5.97295C61.0812 4.50961 50.4739 10.8774 30.5416 15.6839Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M30.5404 15.6842C29.9161 16.3448 41.8886 26.8437 43.9366 27.9702C45.9536 29.0803 50.5099 16.1286 54.9576 13.2561C59.4607 10.3533 61.6504 7.43053 60.8774 5.98568C60.0971 4.5172 53.4 8.45165 45.0887 11.4878C36.8092 14.5011 31.1883 15.0162 30.5404 15.6842Z"
              fill="#905EC0"
              opacity="0.2"
          />
          <path
              d="M72.172 -3.40562C71.2944 -4.51983 61.8264 5.09735 61.2197 7.19124C60.6175 9.2578 53.2654 31.3996 53.0278 31.7197C52.794 32.0516 66.2083 10.3981 72.172 -3.40562Z"
              fill="#4068FF"
          />
          <path
              d="M11.6834 13.3299C12.1437 12.2666 28.2189 15.9042 31.1611 17.9064C34.0223 19.856 49.1344 33.6179 49.1344 33.6179C49.1344 33.6179 23.0989 22.1613 11.6834 13.3299Z"
              fill="#81F2FF"
          />
          <path
              d="M16.3426 -6.52723C16.3426 -6.52723 26.1832 -3.18177 29.4807 -3.999C32.7783 -4.81624 47.2491 -8.09418 50.1529 -10.1371C53.0766 -12.1991 62.9967 -24.1992 37.3966 -16.7761C25.8372 -13.4307 16.3426 -6.52723 16.3426 -6.52723Z"
              fill="#DBF2FF"
          />
          <path
              d="M10.0772 11.9861C11.1072 12.9234 26.3709 15.6596 28.535 15.3242C30.6872 14.9924 30.4124 -1.78541 30.0799 -2.5635C29.7356 -3.33791 21.6601 -4.54872 20.6879 -4.67435C19.7119 -4.8118 28.035 -3.26314 29.3802 -0.894349C30.6989 1.43083 28.521 12.5677 25.2063 13.455C21.8798 14.3461 10.0772 11.9861 10.0772 11.9861Z"
              fill="#FFF2FF"
              opacity="0.5"
          />
          <path
              d="M27.8381 -3.99414C26.5394 -3.66839 30.0868 -0.0525515 30.4968 -0.192922C30.9069 -0.333293 33.8498 -4.66963 33.4927 -4.81789C33.12 -4.9743 27.8381 -3.99414 27.8381 -3.99414Z"
              fill="#DFE5FF"
          />
          <path
              d="M58.1256 22.241C55.9605 23.1989 60.4113 10.2411 62.6808 7.24172C64.9985 4.18846 65.6582 9.44037 64.8334 11.291C64.0167 13.1261 58.1256 22.241 58.1256 22.241Z"
              fill="#FFF2FF"
          />
          <path
              d="M60.0421 6.24562C59.1578 5.65198 60.9038 3.55404 61.1455 3.41414C61.3835 3.26241 64.0466 3.03118 64.2435 3.16441C64.4368 3.28583 61.5045 6.53007 61.1026 6.65495C60.6852 6.77167 60.0421 6.24562 60.0421 6.24562Z"
              fill="#DDE3FF"
          />
          <path
              d="M11.2794 12.467C10.163 12.8787 28.1117 23.6724 31.984 24.9967C35.7825 26.2922 34.1239 19.536 30.9794 17.467C27.7693 15.3536 11.2794 12.467 11.2794 12.467Z"
              fill="#72ACEF"
              opacity="0.4"
          />
          <path
              d="M9.99095 10.9488C10.1814 12.3543 25.8385 16.5236 27.8984 15.3522C29.97 14.177 30.4918 -0.204116 30.4918 -0.204116C30.4918 -0.204116 30.0379 14.6874 30.2977 15.1899C30.4258 15.4353 37.9474 13.487 45.2105 11.1654C52.6391 8.79243 59.8527 6.0196 59.8527 6.0196C59.8527 6.0196 45.8407 12.3176 30.5576 15.6925C30.5576 15.6925 32.6011 18.1813 35.4536 20.9372C35.4536 20.9372 30.2368 17.2049 27.478 16.3771C24.6764 15.5367 12.098 13.016 11.4669 12.8622C10.8048 12.692 9.99095 10.9488 9.99095 10.9488Z"
              fill="#C7D1FF"
          />
          <path
              d="M52.085 -8.48006C52.6568 -7.72448 59.2155 0.82735 59.2155 0.82735L53.0751 -8.83954L52.085 -8.48006Z"
              fill="#C7D1FF"
          />
          <path
              d="M29.7174 2.44784C29.9112 -0.515817 25.5445 -2.93086 20.7356 -3.64015C15.8449 -4.36287 19.45 10.9524 23.4846 12.3819C27.4299 13.7743 29.7174 2.44784 29.7174 2.44784Z"
              fill="#8DFFFF"
          />
          <path
              d="M53.5186 -9.36716C55.0793 -14.648 66.8657 -8.62777 70.12 -5.9967C73.2858 -3.44183 65.0731 2.99853 62.5088 2.42137C59.9172 1.83972 53.5186 -9.36716 53.5186 -9.36716Z"
              fill="#8DCAFF"
          />
          <path
              d="M30.6125 2.13141C29.4902 9.32391 36.3168 1.17557 42.7133 0.445323C49.0471 -0.278375 57.0521 4.75211 58.8797 3.83428C60.7191 2.91278 51.7924 -7.96224 49.9407 -8.54046C48.0735 -9.12684 36.0957 -5.0422 34.4137 -4.15662C32.7708 -3.27025 31.0416 -0.659082 30.6125 2.13141Z"
              fill="#959CC0"
              opacity="0.2"
          />
          <path
              d="M72.1179 -9.36482C71.636 -9.53913 71.6131 -5.94164 70.148 -3.60695C68.7074 -1.31874 64.2305 3.17016 64.2305 3.17016C64.2305 3.17016 70.4258 -1.87862 71.547 -3.86017C72.6926 -5.8882 72.1179 -9.36482 72.1179 -9.36482Z"
              fill="#DDE3FF"
          />
          <path
              d="M10.8559 7.01387C10.8559 7.01387 12.0778 11.1967 12.9599 12.2836C13.8347 13.3469 21.4636 14.9557 21.4636 14.9557C21.4636 14.9557 15.3374 12.4263 13.6847 9.77716C11.9863 7.06444 11.5217 4.90162 11.5217 4.90162L10.8559 7.01387Z"
              fill="#DDE3FF"
          />
          <path
              d="M11.6797 13.3301C11.6797 13.3301 27.3484 15.7849 31.2765 18.2066C35.0807 20.5631 35.4859 25.246 32.7901 25.1634C30.0706 25.0881 11.6797 13.3301 11.6797 13.3301Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M30.8517 16.0438C30.2859 16.1419 38.5686 24.8191 41.2971 25.2156C43.9828 25.5995 47.9533 19.6477 52.4538 15.1515C57.0705 10.5284 58.9706 11.8045 59.4977 9.57979C60.0257 7.31595 60.0473 6.25932 60.0473 6.25932C60.0473 6.25932 46.0748 13.3099 30.8517 16.0438Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M61.3903 6.42475C61.0842 6.64947 59.7831 11.6811 60.1125 11.6565C60.4418 11.632 62.4667 7.01058 63.596 6.34858C64.7372 5.68291 65.8241 8.43008 65.9997 8.07741C66.1753 7.72473 70.7144 -1.9034 70.936 -2.39999C71.1539 -2.9084 64.8137 2.71685 63.764 3.88552C62.7224 5.0387 61.3903 6.42475 61.3903 6.42475Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M72.3599 -10.2853C72.3636 -10.2735 62.5195 -16.8842 60.496 -18.0571C58.4651 -19.2537 50.5652 -20.8172 39.2269 -17.1775C27.8768 -13.5341 19.9661 -8.45785 17.6201 -6.12163C15.3775 -3.86943 11.1158 6.35948 10.8645 6.969C12.4326 1.71185 15.5986 -5.86943 16.3248 -6.53577C16.8831 -7.03329 30.8365 -15.4388 36.2266 -17.1137C41.4395 -18.7334 56.2321 -20.1412 57.4566 -20.2883C59.2853 -20.4936 67.2128 -14.5447 71.4094 -11.0917L71.4249 -11.0835C71.7634 -10.7869 72.0753 -10.5339 72.3599 -10.2853Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <path
              d="M28.0798 15.881C27.2191 16.6151 30.8091 17.5734 31.238 17.6605C31.6669 17.7476 30.5531 15.1643 30.1527 14.8351C29.7604 14.4903 28.0798 15.881 28.0798 15.881Z"
              fill="white"
          />
          <path
              d="M37.0303 24.3869C37.8458 22.4226 45.776 30.3409 45.7023 30.6878C45.6168 31.0384 35.9591 26.9881 37.0303 24.3869Z"
              fill="white"
          />
          <path
              d="M50.9488 33.9593C48.0315 34.7491 11.0847 14.1229 10.0869 11.9962C9.81681 11.419 10.0882 9.83116 10.6423 7.81842C10.7276 8.05116 12.6358 12.941 13.8808 14.2781C14.716 15.1722 25.6706 20.8935 39.6642 29.0534C44.57 31.9102 47.9498 31.8581 49.9692 31.5158C51.9021 31.1874 55.5622 28.1577 58.1304 22.2398C64.2415 8.11808 70.6427 -1.44057 71.6609 -4.12895C73.2554 -8.38329 71.553 -10.9003 71.4352 -11.0711C72.8134 -9.93098 73.8096 -9.06099 74.1081 -8.72601C75.2402 -7.41866 53.866 33.1695 50.9488 33.9593Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <defs>
            <linearGradient
                id="paint0_linear_3071_26356"
                gradientUnits="userSpaceOnUse"
                x1="52.0259"
                x2="36.2602"
                y1="33.6206"
                y2="-17.1186"
            >
              <stop
                  stop-color="#B8FFFF"
              />
              <stop
                  offset="1"
                  stop-color="#6481FF"
              />
            </linearGradient>
          </defs>
        </svg>
        <svg
            fill="none"
            height="61"
            style="position: absolute; left:31px; bottom: 54px"
            viewBox="0 0 62 61"
            width="62"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M42.4048 43.4896C43.849 42.2678 46.3632 15.9013 45.6226 14.631C45.4213 14.2867 44.5069 13.8618 43.2731 13.4314C43.2683 13.4253 43.2683 13.4253 43.2683 13.4253C43.109 13.373 42.945 13.3146 42.7749 13.261C42.7749 13.261 42.764 13.2596 42.7532 13.2583C39.4875 12.1965 34.4565 11.1314 33.855 11.2685C33.4015 11.3682 24.1097 15.5665 21.3302 17.7475C18.6421 19.8566 12.6565 26.9758 12.1452 27.5542C11.3892 28.4231 11.6344 34.6222 11.9206 38.0118L11.9193 38.0227C11.9409 38.3012 11.9651 38.558 11.9919 38.793L11.9967 38.7991C12.065 39.5137 12.129 40.0346 12.1876 40.2348C12.4524 41.2876 40.9605 44.7115 42.4048 43.4896Z"
              fill="url(#paint0_linear_3071_26323)"
          />
          <path
              d="M44.1351 15.2417C45.4601 16.5163 45.3645 33.9536 38.9766 39.9702C35.69 43.0611 29.0039 39.5227 26.146 38.4659C23.1881 37.3695 31.8577 29.73 33.5347 27.9513C35.2239 26.163 37.2927 31.335 38.0261 32.9852C38.7431 34.5893 36.457 37.4367 35.6743 37.9327C34.8963 38.4348 29.1564 36.0646 30.3982 34.2334C31.6584 32.3878 33.8871 33.9481 33.8386 32.2922C33.7879 30.6086 27.6151 33.9747 28.371 36.5823C29.1034 39.1098 33.9027 40.4946 37.341 37.1075C40.7989 33.6951 39.9634 28.5009 33.7677 25.7004C27.0444 22.6541 25.9573 33.0794 24.2686 38.0186C22.6397 42.783 14.2033 38.018 12.5493 35.4455C10.8248 32.7707 14.5183 23.885 21.3833 18.2129C28.2713 12.5326 38.0924 9.43563 44.1351 15.2417Z"
              fill="#B3B0FF"
              opacity="0.2"
          />
          <path
              d="M33.8494 11.2669C35.2788 10.7165 31.2804 18.0589 30.4796 18.9335C29.6848 19.8033 21.4358 26.4727 19.9665 27.399C18.4911 28.3302 12.1674 28.3285 11.9409 27.9646C11.7192 27.6067 19.4905 18.86 21.5366 17.5894C23.5705 16.3283 32.7503 11.6861 33.8494 11.2669Z"
              fill="white"
          />
          <path
              d="M45.1029 14.8451C45.5936 15.6714 41.3949 25.2686 40.9733 25.6979C40.5518 26.1271 30.1325 19.4121 30.4831 18.9302C30.8337 18.4482 33.8887 12.3384 34.4765 11.8575C35.0595 11.3706 44.081 13.1159 45.1029 14.8451Z"
              fill="#8DE1FF"
          />
          <path
              d="M40.2439 26.4816C41.0646 25.8081 30.829 20.2686 30.0802 20.1175C29.3253 19.9712 20.4955 27.0176 20.0897 27.9564C19.6901 28.8904 23.2289 37.7935 23.6229 38.2329C24.0216 38.6783 35.5098 30.3636 40.2439 26.4816Z"
              fill="#A69CE7"
              opacity="0.2"
          />
          <path
              d="M12.1802 40.2282C13.516 41.78 22.5857 39.0559 23.1038 38.6494C23.6219 38.2428 19.4133 28.7622 18.9928 28.3142C18.5675 27.86 13.2337 28.6179 12.4771 29.0343C11.7266 29.4459 12.1802 40.2282 12.1802 40.2282Z"
              fill="#635EC0"
              opacity="0.4"
          />
          <path
              d="M40.2392 26.4797C41.1118 25.6966 42.6677 42.2516 42.3603 43.1526C42.059 44.0488 24.8853 39.62 24.2565 38.9316C23.6229 38.2371 30.6751 35.0377 40.2392 26.4797Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M40.2431 26.4812C40.8054 26.3945 41.9559 36.2952 41.8078 37.7507C41.6624 39.1845 33.3706 36.9257 30.3093 38.1952C27.2127 39.4825 24.9284 39.571 24.4591 38.6591C23.9802 37.7349 28.3884 35.6843 32.908 32.4828C37.4044 29.2896 39.6687 26.5774 40.2431 26.4812Z"
              fill="#905EC0"
              opacity="0.2"
          />
          <path
              d="M15.6039 41.1322C15.3421 40.2839 23.6629 38.8269 24.9572 39.259C26.2359 39.6837 40.2558 43.7706 40.5051 43.762C40.7593 43.7596 24.8351 42.9691 15.6039 41.1322Z"
              fill="#4068FF"
          />
          <path
              d="M45.7361 15.9247C45.0247 15.7837 41.1819 25.3577 41.167 27.5853C41.1538 29.7524 42.8685 42.4306 42.8685 42.4306C42.8685 42.4306 46.2257 24.9455 45.7361 15.9247Z"
              fill="#81F2FF"
          />
          <path
              d="M33.8485 11.268C33.8485 11.268 32.0723 17.5266 30.4787 18.9346C28.8851 20.3426 22.0505 26.6309 19.9656 27.4002C17.8637 28.1729 8.15469 29.0154 21.0821 18.4684C26.916 13.7038 33.8485 11.268 33.8485 11.268Z"
              fill="#DBF2FF"
          />
          <path
              d="M45.6148 14.6239C45.7313 15.4878 41.7128 24.3232 40.7704 25.3187C39.8342 26.3094 31.2895 20.2014 31.0069 19.7534C30.7305 19.3007 32.9786 14.7103 33.2596 14.1648C33.5359 13.6132 31.3738 18.4512 32.1156 19.9865C32.8443 21.4927 39.3555 24.3312 40.9913 22.9394C42.6333 21.5427 45.6148 14.6239 45.6148 14.6239Z"
              fill="#FFF2FF"
              opacity="0.5"
          />
          <path
              d="M32.773 14.5474C32.773 14.5474 29.3976 16.2701 26.3449 19.5713C23.3131 22.8365 24.6384 23.8352 24.6384 23.8352C24.6384 23.8352 29.4537 20.1948 30.5895 18.8309C31.7327 17.4514 32.773 14.5474 32.773 14.5474Z"
              fill="white"
          />
          <path
              d="M20.3197 27.2384C20.1597 27.0094 18.4438 27.7846 18.2973 28.1753C18.1521 28.5551 19.4308 29.6199 19.8931 29.3557C20.3568 29.0805 20.3197 27.2384 20.3197 27.2384Z"
              fill="#DFE5FF"
          />
          <path
              d="M31.0636 18.0909C31.6932 17.5378 32.2942 20.6509 32.0761 20.8122C31.858 20.9735 28.5778 20.9474 28.6284 20.7107C28.6803 20.4631 31.0636 18.0909 31.0636 18.0909Z"
              fill="#DFE5FF"
          />
          <path
              d="M33.8121 43.0194C35.0754 42.2448 26.818 39.9294 24.4659 40.0319C22.069 40.1399 24.5397 42.3474 25.7862 42.5806C27.0219 42.8125 33.8121 43.0194 33.8121 43.0194Z"
              fill="#FFF2FF"
          />
          <path
              d="M24.8922 38.3202C24.9009 37.6536 23.1993 37.8069 23.0413 37.8817C22.8785 37.9503 21.8123 39.2398 21.8109 39.3887C21.8047 39.5314 24.5186 39.1746 24.7259 39.012C24.9345 38.8385 24.8922 38.3202 24.8922 38.3202Z"
              fill="#DDE3FF"
          />
          <path
              d="M45.4335 15.4125C46.0425 14.9838 45.2196 28.0677 44.5247 30.5333C43.8412 32.9506 40.9509 29.6936 41.0034 27.3381C41.0563 24.933 45.4335 15.4125 45.4335 15.4125Z"
              fill="#72ACEF"
              opacity="0.4"
          />
          <path
              d="M45.1149 14.2094C45.7712 14.8073 42.3509 24.355 41.015 24.9995C39.6729 25.6487 32.0796 20.8033 32.0796 20.8033C32.0796 20.8033 39.9117 25.8652 40.0782 26.1777C40.159 26.3309 36.4806 29.5126 32.7019 32.4282C28.8379 35.4108 24.8443 38.1405 24.8443 38.1405C24.8443 38.1405 33.0713 33.1626 40.2446 26.4903C40.2446 26.4903 40.7999 28.428 41.2051 30.8773C41.2051 30.8773 41.1378 26.8629 41.6924 25.1475C42.2558 23.4055 45.4305 16.0299 45.5756 15.6501C45.7234 15.2485 45.1149 14.2094 45.1149 14.2094Z"
              fill="#C7D1FF"
          />
          <path
              d="M20.1363 28.9814C20.3222 29.5446 22.3949 35.9642 22.3949 35.9642L19.5991 29.3636L20.1363 28.9814Z"
              fill="#C7D1FF"
          />
          <path
              d="M33.7128 21.3487C32.1173 20.3947 32.4262 17.2865 33.7709 14.5572C35.1379 11.7809 41.7448 19.0843 41.0464 21.6709C40.3606 24.1983 33.7128 21.3487 33.7128 21.3487Z"
              fill="#8DFFFF"
          />
          <path
              d="M19.1696 29.4054C15.8944 28.3314 14.804 36.5435 15.002 39.1554C15.1922 41.6947 21.4303 39.7546 22.0449 38.2285C22.6669 36.6867 19.1696 29.4054 19.1696 29.4054Z"
              fill="#8DCAFF"
          />
          <path
              d="M33.2378 21.6954C37.3418 23.6751 30.7169 24.2938 28.0661 27.329C25.4409 30.3342 25.1854 36.2465 24.0627 36.8615C22.9339 37.4813 20.5066 29.0158 20.8673 27.8564C21.2292 26.686 27.5927 21.9688 28.647 21.4173C29.6879 20.8862 31.6478 20.9241 33.2378 21.6954Z"
              fill="#959CC0"
              opacity="0.2"
          />
          <path
              d="M12.5527 38.988C12.6343 38.6778 14.4955 39.9453 16.2192 40.0209C17.9101 40.0925 21.8144 39.3828 21.8144 39.3828C21.8144 39.3828 17.0106 40.7786 15.5912 40.6515C14.1392 40.5204 12.5527 38.988 12.5527 38.988Z"
              fill="#DDE3FF"
          />
          <path
              d="M42.7794 13.2549C42.7794 13.2549 44.4995 15.3718 44.7456 16.2127C44.9822 17.0414 43.098 21.5432 43.098 21.5432C43.098 21.5432 43.9737 17.4881 43.1968 15.6947C42.4034 13.8551 41.4546 12.8467 41.4546 12.8467L42.7794 13.2549Z"
              fill="#DDE3FF"
          />
          <path
              d="M45.7344 15.9258C45.7344 15.9258 41.4268 24.8698 41.2774 27.7544C41.1384 30.552 43.4065 32.426 44.3226 31.008C45.2509 29.5804 45.7344 15.9258 45.7344 15.9258Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M40.3204 26.7656C40.5721 26.509 42.0963 33.8612 41.3303 35.4077C40.5729 36.9277 36.0952 36.8564 32.1787 37.5757C28.1555 38.3097 28.1372 39.7422 26.8038 39.2226C25.4499 38.6895 24.8979 38.3249 24.8979 38.3249C24.8979 38.3249 33.4985 33.6349 40.3204 26.7656Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M24.5001 39.0785C24.7247 39.0008 27.7792 40.1199 27.6495 40.2808C27.5197 40.4417 24.4191 39.8413 23.6765 40.1876C22.9278 40.5387 23.9563 42.0755 23.7122 42.0405C23.4681 42.0056 16.8945 40.9198 16.5599 40.8573C16.2205 40.7888 21.3728 39.5233 22.348 39.3982C23.3124 39.2718 24.5001 39.0785 24.5001 39.0785Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M11.9953 38.789C12.0001 38.7951 12.0956 31.3735 12.211 29.914C12.3169 28.4424 14.3209 23.8171 20.2277 19.271C26.1407 14.7201 31.5687 12.4505 33.6064 12.0728C35.564 11.7186 42.3485 13.1609 42.7518 13.2482C39.4862 12.1864 34.4551 11.1213 33.8537 11.2584C33.3988 11.369 24.1071 15.5673 21.3275 17.7482C18.6394 19.8574 12.6538 26.9766 12.1425 27.555C11.3865 28.4239 11.6317 34.623 11.918 38.0126L11.9166 38.0235C11.9491 38.3033 11.9685 38.554 11.9953 38.789Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <path
              d="M41.2208 25.2801C41.905 25.0978 41.122 27.2879 41.0143 27.5398C40.9067 27.7916 39.9721 26.2993 39.9449 25.9759C39.9068 25.6513 41.2208 25.2801 41.2208 25.2801Z"
              fill="white"
          />
          <path
              d="M42.4179 32.9171C41.1161 32.6386 42.3748 39.5394 42.5797 39.6248C42.7907 39.7055 44.1387 33.2903 42.4179 32.9171Z"
              fill="white"
          />
          <path
              d="M42.4051 43.4889C43.8493 42.2671 46.3635 15.9006 45.6229 14.6303C45.4216 14.286 44.5072 13.8611 43.2734 13.4308C43.3629 13.5574 45.2031 16.2793 45.4491 17.3961C45.6127 18.1442 44.6641 25.8216 43.891 35.9316C43.618 39.4745 42.3892 41.1969 41.4948 42.1154C40.6382 42.9943 37.7761 43.8022 33.8145 43.0206C24.3671 41.1466 17.167 41.0447 15.4201 40.6131C12.6616 39.9216 11.9705 38.1496 11.9244 38.0281C12.0216 39.1435 12.1155 39.966 12.1818 40.2389C12.4527 41.287 40.9608 44.7108 42.4051 43.4889Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <defs>
            <linearGradient
                id="paint0_linear_3071_26323"
                gradientUnits="userSpaceOnUse"
                x1="41.8467"
                x2="21.3171"
                y1="43.9244"
                y2="17.7598"
            >
              <stop
                  stop-color="#B8FFFF"
              />
              <stop
                  offset="1"
                  stop-color="#6481FF"
              />
            </linearGradient>
          </defs>
        </svg>
        <svg
            fill="none"
            height="71"
            style="position: absolute; bottom: -5px; right: 53px;"
            viewBox="0 0 77 71"
            width="77"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M25.1853 53.8938C23.3955 52.3796 20.2798 19.7054 21.1977 18.1312C21.4471 17.7045 22.5803 17.1779 24.1093 16.6447C24.1152 16.6371 24.1152 16.6371 24.1152 16.6371C24.3125 16.5723 24.5158 16.4999 24.7266 16.4334C24.7266 16.4334 24.7401 16.4318 24.7536 16.4301C28.8004 15.1143 35.0351 13.7943 35.7805 13.9642C36.3425 14.0879 47.8571 19.2906 51.3016 21.9932C54.6328 24.607 62.0504 33.4293 62.684 34.1461C63.6208 35.2229 63.317 42.9049 62.9623 47.1055L62.9639 47.119C62.9372 47.4642 62.9072 47.7823 62.8739 48.0736L62.868 48.0811C62.7834 48.9667 62.704 49.6122 62.6314 49.8603C62.3033 51.165 26.975 55.4079 25.1853 53.8938Z"
              fill="url(#paint0_linear_3071_26290)"
          />
          <path
              d="M23.0262 18.8869C21.3841 20.4665 21.5027 42.0753 29.4187 49.5313C33.4916 53.3617 41.7772 48.9768 45.3188 47.6672C48.9844 46.3085 38.2407 36.8413 36.1625 34.6371C34.0692 32.421 31.5055 38.8303 30.5966 40.8753C29.7081 42.8631 32.5411 46.3918 33.5111 47.0064C34.4752 47.6286 41.5883 44.6913 40.0493 42.422C38.4877 40.135 35.7258 42.0685 35.7859 40.0165C35.8487 37.9301 43.4983 42.1016 42.5616 45.3329C41.6539 48.4651 35.7064 50.1812 31.4456 45.9837C27.1604 41.755 28.1958 35.3181 35.8737 31.8477C44.2056 28.0726 45.5527 40.992 47.6454 47.1129C49.664 53.017 60.1186 47.1121 62.1684 43.9242C64.3054 40.6095 59.7283 29.598 51.221 22.569C42.6851 15.5298 30.5145 11.6919 23.0262 18.8869Z"
              fill="#B3B0FF"
              opacity="0.2"
          />
          <path
              d="M35.7785 13.9628C34.0071 13.2807 38.9621 22.3797 39.9545 23.4635C40.9394 24.5414 51.1619 32.8063 52.9827 33.9543C54.811 35.1082 62.6476 35.1062 62.9283 34.6551C63.203 34.2117 53.5725 23.3724 51.0369 21.7978C48.5164 20.2351 37.1405 14.4823 35.7785 13.9628Z"
              fill="white"
          />
          <path
              d="M21.825 18.3964C21.2169 19.4203 26.4202 31.3136 26.9425 31.8455C27.4649 32.3774 40.3769 24.0559 39.9424 23.4587C39.5079 22.8615 35.722 15.29 34.9936 14.6941C34.2711 14.0906 23.0914 16.2535 21.825 18.3964Z"
              fill="#8DE1FF"
          />
          <path
              d="M27.8574 32.817C26.8403 31.9823 39.5246 25.1176 40.4526 24.9304C41.3881 24.7491 52.3303 33.4811 52.8331 34.6445C53.3284 35.802 48.9429 46.8351 48.4547 47.3795C47.9606 47.9315 33.724 37.6276 27.8574 32.817Z"
              fill="#A69CE7"
              opacity="0.2"
          />
          <path
              d="M62.6345 49.8523C60.9792 51.7754 49.7397 48.3996 49.0976 47.8958C48.4556 47.392 53.671 35.6433 54.1921 35.088C54.7192 34.5252 61.329 35.4645 62.2666 35.9805C63.1967 36.4905 62.6345 49.8523 62.6345 49.8523Z"
              fill="#635EC0"
              opacity="0.4"
          />
          <path
              d="M27.8548 32.8158C26.7734 31.8453 24.8453 52.3608 25.2262 53.4774C25.5996 54.588 46.8818 49.0997 47.6611 48.2466C48.4462 47.3859 39.7069 43.4211 27.8548 32.8158Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M27.856 32.8155C27.1591 32.7081 25.7335 44.9774 25.9169 46.7811C26.0972 48.5579 36.3726 45.7587 40.1664 47.3319C44.0038 48.9272 46.8344 49.0369 47.4161 47.9067C48.0096 46.7615 42.5467 44.2203 36.946 40.2529C31.3738 36.2958 28.5679 32.9348 27.856 32.8155Z"
              fill="#905EC0"
              opacity="0.2"
          />
          <path
              d="M58.3866 50.9731C58.7111 49.9219 48.3996 48.1163 46.7957 48.6518C45.2111 49.1781 27.8372 54.2427 27.5282 54.2321C27.2132 54.2291 46.947 53.2495 58.3866 50.9731Z"
              fill="#4068FF"
          />
          <path
              d="M21.0469 19.7351C21.9285 19.5603 26.6906 31.4248 26.7092 34.1853C26.7255 36.8708 24.6006 52.5822 24.6006 52.5822C24.6006 52.5822 20.4402 30.914 21.0469 19.7351Z"
              fill="#81F2FF"
          />
          <path
              d="M35.7764 13.9637C35.7764 13.9637 37.9775 21.7195 39.9524 23.4644C41.9273 25.2092 50.3968 33.0019 52.9806 33.9552C55.5853 34.9128 67.6171 35.9568 51.5969 22.8866C44.3673 16.9821 35.7764 13.9637 35.7764 13.9637Z"
              fill="#DBF2FF"
          />
          <path
              d="M21.1948 18.1232C21.0505 19.1938 26.0304 30.1429 27.1982 31.3765C28.3584 32.6042 38.9473 25.035 39.2974 24.4798C39.64 23.9188 36.8541 18.2303 36.5058 17.5543C36.1635 16.8707 38.8428 22.8661 37.9236 24.7687C37.0205 26.6352 28.9516 30.1528 26.9244 28.428C24.8897 26.6973 21.1948 18.1232 21.1948 18.1232Z"
              fill="#FFF2FF"
              opacity="0.5"
          />
          <path
              d="M37.116 18.0271C37.116 18.0271 41.2989 20.162 45.0819 24.253C48.839 28.2993 47.1967 29.537 47.1967 29.537C47.1967 29.537 41.2294 25.0257 39.8218 23.3355C38.4051 21.6259 37.116 18.0271 37.116 18.0271Z"
              fill="white"
          />
          <path
              d="M52.5417 33.7556C52.7399 33.4719 54.8664 34.4325 55.0479 34.9166C55.2278 35.3873 53.6432 36.7069 53.0703 36.3794C52.4957 36.0384 52.5417 33.7556 52.5417 33.7556Z"
              fill="#DFE5FF"
          />
          <path
              d="M39.227 22.4181C38.4467 21.7326 37.7019 25.5905 37.9722 25.7904C38.2425 25.9903 42.3075 25.9579 42.2447 25.6646C42.1803 25.3578 39.227 22.4181 39.227 22.4181Z"
              fill="#DFE5FF"
          />
          <path
              d="M35.8241 53.3112C34.2585 52.3513 44.4913 49.482 47.4061 49.6089C50.3765 49.7428 47.3147 52.4784 45.7699 52.7674C44.2386 53.0548 35.8241 53.3112 35.8241 53.3112Z"
              fill="#FFF2FF"
          />
          <path
              d="M46.8785 47.4873C46.8677 46.6611 48.9763 46.8511 49.1721 46.9438C49.3739 47.0288 50.6951 48.6269 50.6968 48.8113C50.7045 48.9882 47.3413 48.5461 47.0845 48.3445C46.826 48.1296 46.8785 47.4873 46.8785 47.4873Z"
              fill="#DDE3FF"
          />
          <path
              d="M21.4187 19.1007C20.6639 18.5695 21.6838 34.7835 22.5449 37.8389C23.3919 40.8345 26.9737 36.7984 26.9087 33.8794C26.843 30.8989 21.4187 19.1007 21.4187 19.1007Z"
              fill="#72ACEF"
              opacity="0.4"
          />
          <path
              d="M21.8226 17.6091C21.0094 18.35 25.2478 30.1819 26.9034 30.9805C28.5665 31.7851 37.9764 25.7805 37.9764 25.7805C37.9764 25.7805 28.2706 32.0533 28.0643 32.4406C27.9641 32.6305 32.5225 36.5733 37.2052 40.1865C41.9937 43.8826 46.9427 47.2654 46.9427 47.2654C46.9427 47.2654 36.7475 41.0965 27.858 32.828C27.858 32.828 27.1699 35.2292 26.6677 38.2646C26.6677 38.2646 26.7512 33.2898 26.0639 31.1639C25.3657 29.0052 21.4315 19.8651 21.2517 19.3944C21.0685 18.8968 21.8226 17.6091 21.8226 17.6091Z"
              fill="#C7D1FF"
          />
          <path
              d="M52.773 35.915C52.5427 36.6129 49.9741 44.5683 49.9741 44.5683L53.4388 36.3885L52.773 35.915Z"
              fill="#C7D1FF"
          />
          <path
              d="M35.9426 26.4563C37.9199 25.274 37.5371 21.4223 35.8707 18.04C34.1767 14.5996 25.9891 23.6502 26.8547 26.8555C27.7045 29.9876 35.9426 26.4563 35.9426 26.4563Z"
              fill="#8DFFFF"
          />
          <path
              d="M53.9651 36.4391C58.0238 35.1082 59.375 45.2848 59.1297 48.5217C58.894 51.6685 51.1636 49.2642 50.402 47.373C49.6311 45.4623 53.9651 36.4391 53.9651 36.4391Z"
              fill="#8DCAFF"
          />
          <path
              d="M36.5392 26.8856C31.4534 29.3389 39.6632 30.1057 42.9482 33.867C46.2014 37.5911 46.518 44.918 47.9093 45.6801C49.3081 46.4481 52.3162 35.9574 51.8692 34.5205C51.4207 33.0702 43.5348 27.2245 42.2283 26.541C40.9384 25.8829 38.5096 25.9298 36.5392 26.8856Z"
              fill="#959CC0"
              opacity="0.2"
          />
          <path
              d="M62.1694 48.3155C62.0683 47.9311 59.7619 49.5019 57.6259 49.5955C55.5304 49.6843 50.692 48.8048 50.692 48.8048C50.692 48.8048 56.645 50.5345 58.404 50.377C60.2035 50.2145 62.1694 48.3155 62.1694 48.3155Z"
              fill="#DDE3FF"
          />
          <path
              d="M24.7169 16.4266C24.7169 16.4266 22.5853 19.0499 22.2803 20.092C21.9871 21.119 24.3221 26.6977 24.3221 26.6977C24.3221 26.6977 23.2369 21.6725 24.1996 19.4501C25.1828 17.1705 26.3587 15.9208 26.3587 15.9208L24.7169 16.4266Z"
              fill="#DDE3FF"
          />
          <path
              d="M21.0469 19.7363C21.0469 19.7363 26.385 30.8201 26.5702 34.3947C26.7423 37.8616 23.9316 40.1839 22.7964 38.4267C21.646 36.6576 21.0469 19.7363 21.0469 19.7363Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M27.7623 33.17C27.4504 32.8521 25.5615 41.9632 26.5108 43.8796C27.4493 45.7632 32.9983 45.6748 37.8518 46.5662C42.8374 47.4759 42.8601 49.2511 44.5125 48.6072C46.1903 47.9465 46.8743 47.4947 46.8743 47.4947C46.8743 47.4947 36.2161 41.6826 27.7623 33.17Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M47.3639 48.4263C47.0856 48.3299 43.3003 49.7167 43.4611 49.9162C43.622 50.1156 47.4643 49.3715 48.3846 49.8007C49.3124 50.2358 48.0378 52.1403 48.3403 52.0969C48.6428 52.0536 56.7891 50.7081 57.2037 50.6307C57.6243 50.5457 51.2394 48.9775 50.0308 48.8224C48.8357 48.6658 47.3639 48.4263 47.3639 48.4263Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M62.8621 48.0677C62.8562 48.0753 62.7379 38.8782 62.5948 37.0696C62.4636 35.2459 59.9803 29.514 52.6602 23.8804C45.3327 18.2408 38.6062 15.4281 36.081 14.9601C33.6551 14.5212 25.2475 16.3086 24.7477 16.4167C28.7945 15.1009 35.0292 13.7809 35.7746 13.9508C36.3382 14.088 47.8528 19.2907 51.2973 21.9933C54.6285 24.6071 62.0461 33.4293 62.6797 34.1461C63.6166 35.223 63.3127 42.905 62.958 47.1056L62.9596 47.1191C62.9194 47.4659 62.8954 47.7765 62.8621 48.0677Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <path
              d="M26.6469 31.3289C25.799 31.103 26.7694 33.817 26.9028 34.1292C27.0362 34.4413 28.1944 32.5919 28.2281 32.1912C28.2753 31.7889 26.6469 31.3289 26.6469 31.3289Z"
              fill="white"
          />
          <path
              d="M25.1586 40.7928C26.7718 40.4477 25.2121 48.9993 24.9582 49.1052C24.6967 49.2051 23.0261 41.2553 25.1586 40.7928Z"
              fill="white"
          />
          <path
              d="M25.1838 53.893C23.3941 52.3788 20.2784 19.7046 21.1963 18.1304C21.4457 17.7037 22.5789 17.1771 24.1079 16.6438C23.9969 16.8008 21.7164 20.1739 21.4116 21.5578C21.2089 22.485 22.3844 31.999 23.3424 44.5277C23.6808 48.9182 25.2035 51.0526 26.3119 52.1908C27.3734 53.28 30.9203 54.2812 35.8297 53.3126C47.5372 50.9902 56.4598 50.864 58.6246 50.3292C62.043 49.4722 62.8995 47.2762 62.9566 47.1257C62.8361 48.508 62.7198 49.5273 62.6375 49.8654C62.3019 51.1642 26.9736 55.4071 25.1838 53.893Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <defs>
            <linearGradient
                id="paint0_linear_3071_26290"
                gradientUnits="userSpaceOnUse"
                x1="25.8769"
                x2="51.3178"
                y1="54.4325"
                y2="22.0085"
            >
              <stop
                  stop-color="#B8FFFF"
              />
              <stop
                  offset="1"
                  stop-color="#6481FF"
              />
            </linearGradient>
          </defs>
        </svg>
        <svg
            fill="none"
            height="120"
            style="right: 0; position: absolute; z-index: 0;"
            viewBox="0 0 79 120"
            width="79"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M68.7551 97.3053C64.6149 98.2526 13.8783 67.2413 12.5951 64.1996C12.2479 63.3742 12.717 61.1596 13.6066 58.364C13.6021 58.3472 13.6021 58.3472 13.6021 58.3472C13.7227 57.9908 13.8388 57.6175 13.9717 57.2398C13.9717 57.2398 13.984 57.2185 13.9963 57.1972C16.4896 49.9021 21.3556 39.4321 22.4123 38.5367C23.2025 37.8567 43.2616 26.8274 50.9232 24.7745C58.3328 22.7891 79.1824 21.6324 80.9101 21.4937C83.4892 21.3068 94.2911 30.0997 99.9925 35.181L100.014 35.1933C100.485 35.6073 100.913 35.9967 101.299 36.3615L101.304 36.3783C102.495 37.4638 103.344 38.2807 103.625 38.6557C105.159 40.5498 72.8952 96.358 68.7551 97.3053Z"
              fill="url(#paint0_linear_3071_26257)"
          />
          <path
              d="M15.6847 62.3619C16.1882 66.4589 47.631 89.7821 67.0427 86.4176C77.0218 84.6801 79.6763 67.9056 81.6293 61.3496C83.6459 54.5604 58.2454 59.8358 52.7916 60.4507C47.3042 61.0747 53.8046 71.7585 55.7798 75.2991C57.6943 78.7479 65.8881 78.4793 67.8334 77.7419C69.7833 77.0213 73.2608 63.5199 68.2991 63.2827C63.2869 63.059 63.0858 69.1636 60.1776 66.8454C57.2223 64.4859 71.5846 57.9363 75.2488 62.8071C78.8008 67.5279 74.8211 78.0125 64.1056 79.6232C53.3184 81.235 45.1164 72.7359 48.4354 57.8363C52.024 41.6578 72.2105 53.7528 83.3557 57.3754C94.1064 60.8696 96.9165 39.2993 94.5256 32.8627C92.0458 26.1619 71.1122 20.8221 51.6763 25.5077C32.1946 30.2235 13.4001 43.6873 15.6847 62.3619Z"
              fill="#B3B0FF"
              opacity="0.2"
          />
          <path
              d="M22.4093 38.5365C19.4948 40.3619 38.0678 43.0748 40.7174 42.815C43.3502 42.5598 66.442 36.7325 70.0852 35.3421C73.7453 33.9472 82.263 22.5888 81.9146 21.6917C81.5707 20.8114 55.3921 22.9818 50.3534 24.9442C45.3484 26.8976 24.6429 37.1277 22.4093 38.5365Z"
              fill="white"
          />
          <path
              d="M13.6643 63.5673C14.4869 65.5619 37.3792 70.9532 38.7179 70.7745C40.0567 70.5959 42.0369 42.837 40.6991 42.8173C39.3612 42.7976 24.2729 40.0514 22.6173 40.459C20.9572 40.8498 11.936 59.4023 13.6643 63.5673Z"
              fill="#8DE1FF"
          />
          <path
              d="M41.1143 70.5126C38.7989 71.079 42.6426 45.2338 43.3803 43.6856C44.1348 42.1328 68.6858 35.7705 70.9185 36.3067C73.1343 36.8475 84.3543 55.1988 84.6125 56.4982C84.8751 57.8145 54.4643 67.2416 41.1143 70.5126Z"
              fill="#A69CE7"
              opacity="0.2"
          />
          <path
              d="M103.617 38.6379C104.604 43.1276 87.491 55.7445 86.0628 56.1272C84.6346 56.5099 73.28 36.1778 73.042 34.8189C72.7994 33.4433 81.3474 24.886 83.1146 24.0884C84.8649 23.2952 103.617 38.6379 103.617 38.6379Z"
              fill="#635EC0"
              opacity="0.4"
          />
          <path
              d="M41.1145 70.5109C38.5324 71.0227 66.1655 96.1231 68.1977 96.7851C70.2132 97.4516 85.3998 60.6436 85.0108 58.5869C84.6172 56.5133 69.3696 64.8668 41.1145 70.5109Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M41.1168 70.5118C40.2036 71.4048 56.4331 86.8111 59.2465 88.5063C62.0172 90.177 69.1332 72.2431 75.5378 68.456C82.0219 64.6297 85.2587 60.647 84.2534 58.5753C83.239 56.47 73.6169 61.6234 61.778 65.4259C49.985 69.1981 42.0637 69.6098 41.1168 70.5118Z"
              fill="#905EC0"
              opacity="0.2"
          />
          <path
              d="M100.62 46.0131C99.4495 44.3999 85.6215 57.3792 84.6535 60.2858C83.6933 63.1543 72.1421 93.8379 71.7907 94.2742C71.4439 94.7272 91.4807 65.0654 100.62 46.0131Z"
              fill="#4068FF"
          />
          <path
              d="M14.7581 66.1589C15.4634 64.6913 37.8342 70.6905 41.8547 73.665C45.7641 76.5613 66.2214 96.7233 66.2214 96.7233C66.2214 96.7233 30.298 79.1927 14.7581 66.1589Z"
              fill="#81F2FF"
          />
          <path
              d="M22.4086 38.5364C22.4086 38.5364 36.041 43.7796 40.7168 42.8149C45.3925 41.8502 65.8938 38.0496 70.0846 35.342C74.3044 32.6086 88.8993 16.3083 52.5404 25.3123C36.1235 29.3691 22.4086 38.5364 22.4086 38.5364Z"
              fill="#DBF2FF"
          />
          <path
              d="M12.5826 64.1832C13.977 65.5564 35.2583 70.2447 38.3156 69.8937C41.3562 69.5472 41.9006 45.9728 41.4768 44.8618C41.0362 43.7553 29.7639 41.6074 28.4056 41.3771C27.0428 41.1299 38.644 43.766 40.4017 47.1668C42.1245 50.5049 38.4488 66.0223 33.7452 67.0846C29.0248 68.1513 12.5826 64.1832 12.5826 64.1832Z"
              fill="#FFF2FF"
              opacity="0.5"
          />
          <path
              d="M29.7533 41.0147C29.7533 41.0147 37.395 37.2744 47.4366 36.2405C57.3852 35.1955 57.393 38.9211 57.393 38.9211C57.393 38.9211 44.3674 42.6634 40.3877 42.8654C36.3699 43.0595 29.7533 41.0147 29.7533 41.0147Z"
              fill="white"
          />
          <path
              d="M69.315 35.7643C69.1193 35.1685 72.8234 33.1315 73.7224 33.3949C74.6 33.6459 74.7894 37.3769 73.6919 37.851C72.5731 38.3129 69.315 35.7643 69.315 35.7643Z"
              fill="#DFE5FF"
          />
          <path
              d="M38.4098 42.7306C36.5682 43.1161 41.3489 48.39 41.9324 48.2157C42.516 48.0413 46.8889 42.1154 46.3956 41.8875C45.881 41.6472 38.4098 42.7306 38.4098 42.7306Z"
              fill="#DFE5FF"
          />
          <path
              d="M79.4824 81.2486C76.3891 82.4736 83.3572 64.5252 86.7104 60.4393C90.1341 56.2805 90.7693 63.6917 89.5084 66.2445C88.2599 68.7761 79.4824 81.2486 79.4824 81.2486Z"
              fill="#FFF2FF"
          />
          <path
              d="M14.2457 64.9271C12.6552 65.4433 37.2601 81.5947 42.6241 83.669C47.8861 85.6986 45.9317 76.1197 41.631 73.0402C37.2405 69.8946 14.2457 64.9271 14.2457 64.9271Z"
              fill="#72ACEF"
              opacity="0.4"
          />
          <path
              d="M12.5169 62.7242C12.7064 64.7083 34.4607 71.4309 37.418 69.9002C40.3922 68.3649 41.9222 48.2 41.9222 48.2C41.9222 48.2 40.4592 69.0853 40.7962 69.8054C40.9624 70.157 51.6322 67.8383 61.9596 64.981C72.5223 62.0607 82.8052 58.5671 82.8052 58.5671C82.8052 58.5671 62.7807 66.6339 41.1332 70.5254C41.1332 70.5254 43.8647 74.1335 47.7173 78.1615C47.7173 78.1615 40.599 72.6315 36.771 71.3161C32.8836 69.9807 15.3609 65.7438 14.4833 65.4928C13.563 65.2171 12.5169 62.7242 12.5169 62.7242Z"
              fill="#C7D1FF"
          />
          <path
              d="M72.6977 37.7742C73.4587 38.8669 82.1942 51.2389 82.1942 51.2389L74.1078 37.3244L72.6977 37.7742Z"
              fill="#C7D1FF"
          />
          <path
              d="M40.6913 51.8793C41.1278 47.7285 35.13 44.0953 28.4168 42.8327C21.5893 41.5467 25.8025 63.2521 31.3885 65.483C36.8512 67.6569 40.6913 51.8793 40.6913 51.8793Z"
              fill="#8DFFFF"
          />
          <path
              d="M74.7585 36.6095C77.2428 29.2809 93.4592 38.3878 97.883 42.2627C102.187 46.0257 90.2975 54.6138 86.7288 53.6612C83.1219 52.7008 74.7585 36.6095 74.7585 36.6095Z"
              fill="#8DCAFF"
          />
          <path
              d="M41.9594 51.4856C39.9849 61.523 50.0223 50.4597 59.0447 49.7889C67.9786 49.1238 78.9403 56.6314 81.5575 55.4439C84.1914 54.2519 72.2596 38.4865 69.6915 37.5719C67.1021 36.645 50.0567 41.7165 47.6456 42.8668C45.2895 44.0203 42.7167 47.591 41.9594 51.4856Z"
              fill="#959CC0"
              opacity="0.2"
          />
          <path
              d="M13.948 57.2439C13.948 57.2439 15.4319 63.1852 16.6103 64.7603C17.7797 66.3018 28.4028 68.9838 28.4028 68.9838C28.4028 68.9838 19.9408 65.0924 17.767 61.2809C15.5325 57.3776 15 54.3148 15 54.3148L13.948 57.2439Z"
              fill="#DDE3FF"
          />
          <path
              d="M14.7578 66.1582C14.7578 66.1582 36.6235 70.4739 42.0051 74.0922C47.2161 77.6121 47.5254 84.2102 43.7446 83.9447C39.9302 83.6882 14.7578 66.1582 14.7578 66.1582Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M41.5237 71.0337C40.7238 71.14 51.8731 83.7836 55.6825 84.4917C59.4324 85.1797 65.3377 77.0424 71.9065 70.9783C78.6455 64.7426 81.2428 66.6399 82.1063 63.5452C82.9732 60.3956 83.0622 58.9131 83.0622 58.9131C83.0622 58.9131 63.0512 68.0387 41.5237 71.0337Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M84.9407 59.2178C84.4984 59.5163 82.3925 66.5095 82.8563 66.4933C83.3202 66.477 86.4196 60.1 88.0422 59.2331C89.6815 58.3616 91.0554 62.2794 91.3215 61.7939C91.5876 61.3085 98.4952 48.0404 98.8339 47.3554C99.1681 46.6536 89.9533 54.201 88.4145 55.7838C86.8881 57.3453 84.9407 59.2178 84.9407 59.2178Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M101.279 36.3651C101.283 36.3819 87.8269 26.5535 85.0505 24.7943C82.2651 23.0015 71.2588 20.3681 55.136 24.8502C38.9964 29.3369 27.6069 36.0264 24.1831 39.1768C20.9093 42.2149 14.358 56.342 13.9713 57.1839C16.4646 49.8888 21.3306 39.4188 22.3873 38.5234C23.1988 37.8558 43.2579 26.8264 50.9195 24.7735C58.3291 22.7881 79.1788 21.6315 80.9064 21.4927C83.4855 21.3058 94.2874 30.0988 99.9889 35.18L100.01 35.1923C100.469 35.6277 100.893 36.0003 101.279 36.3651Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <path
              d="M37.644 70.6505C36.3947 71.6335 41.3827 73.1783 41.9801 73.3244C42.5775 73.4704 41.1568 69.7813 40.6128 69.2968C40.0811 68.7909 37.644 70.6505 37.644 70.6505Z"
              fill="white"
          />
          <path
              d="M49.7415 83.0929C50.9955 80.3798 61.692 91.9382 61.5693 92.4213C61.4298 92.9089 48.0931 86.686 49.7415 83.0929Z"
              fill="white"
          />
          <path
              d="M68.7481 97.3047C64.6079 98.252 13.8713 67.2407 12.5881 64.199C12.2409 63.3736 12.7099 61.159 13.5996 58.3634C13.7064 58.695 16.1149 65.6671 17.7889 67.6136C18.9121 68.9154 33.9772 77.5564 53.1745 89.7904C59.9047 94.0737 64.6534 94.188 67.508 93.8193C70.2404 93.4653 75.5478 89.414 79.4821 81.2466C88.8461 61.7558 98.3646 48.6886 99.9434 44.9701C102.418 39.0846 100.167 35.4558 100.011 35.2095C101.883 36.8869 103.234 38.1637 103.635 38.6506C105.152 40.5492 72.8882 96.3574 68.7481 97.3047Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <defs>
            <linearGradient
                id="paint0_linear_3071_26257"
                gradientUnits="userSpaceOnUse"
                x1="70.2878"
                x2="50.963"
                y1="96.8889"
                y2="24.7676"
            >
              <stop
                  stop-color="#B8FFFF"
              />
              <stop
                  offset="1"
                  stop-color="#6481FF"
              />
            </linearGradient>
          </defs>
        </svg>
        <svg
            fill="none"
            height="173"
            style="position: absolute; left: 0; top: 76px; z-index: 0;"
            viewBox="0 0 108 173"
            width="108"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M-3.28196 129.99C-8.14396 127.254 -30.0844 52.2924 -28.6456 48.2017C-28.2542 47.0926 -25.8422 45.3536 -22.5082 43.4214C-22.4977 43.4011 -22.4977 43.4011 -22.4977 43.4011C-22.0657 43.161 -21.6231 42.9006 -21.1603 42.6507C-21.1603 42.6507 -21.1295 42.6408 -21.0987 42.631C-12.2316 37.7403 1.74665 31.8583 3.56484 31.921C4.93371 31.9578 34.1777 38.9505 43.44 43.7213C52.3977 48.3351 73.6907 65.625 75.493 67.0159C78.1656 69.1121 80.902 87.2015 81.9576 97.1774L81.9674 97.2082C82.0598 98.0268 82.1325 98.7838 82.1855 99.4793L82.175 99.4996C82.3746 101.607 82.4787 103.151 82.4203 103.764C82.2389 106.96 1.58003 132.726 -3.28196 129.99Z"
              fill="url(#paint0_linear_3071_26224)"
          />
          <path
              d="M-24.0122 49.1474C-27.1412 53.5755 -17.1694 104.022 4.67552 117.896C15.9125 125.02 33.3087 111.055 40.9978 106.406C48.9547 101.586 19.5993 84.281 13.7536 80.0621C7.86732 75.8223 4.75145 91.9511 3.54479 97.1379C2.36027 102.182 10.5641 109.158 13.1068 110.159C15.639 111.18 30.9446 101.125 26.3299 96.5118C21.6543 91.8675 16.0671 97.6253 15.287 92.8029C14.4976 87.8986 34.2463 94.2155 33.507 102.187C32.791 109.914 19.6616 116.593 7.82076 108.696C-4.09086 100.735 -4.559 85.2279 11.8273 73.6728C29.6052 61.1124 38.5496 90.7007 46.1863 104.066C53.5528 116.959 75.336 98.4684 78.6961 90.0987C82.2033 81.3935 66.5663 57.7131 43.5311 45.103C20.4245 32.4818 -9.7401 28.9729 -24.0122 49.1474Z"
              fill="#B3B0FF"
              opacity="0.2"
          />
          <path
              d="M3.56336 31.9202C-0.88244 31.121 14.7795 50.1623 17.5851 52.2499C20.3703 54.327 47.9684 69.056 52.7386 70.9219C57.5292 72.7983 75.8425 69.2777 76.296 68.0977C76.7391 66.9381 49.3697 45.9273 42.7375 43.3851C36.146 40.8638 6.97941 32.5232 3.56336 31.9202Z"
              fill="white"
          />
          <path
              d="M-27.0409 48.5384C-28.0027 51.2042 -10.5068 76.6644 -9.04739 77.6731C-7.58797 78.6818 18.8541 53.4416 17.5707 52.2409C16.2873 51.0401 4.04282 35.0441 2.07316 33.9782C0.113959 32.8919 -25.0427 42.9625 -27.0409 48.5384Z"
              fill="#8DE1FF"
          />
          <path
              d="M-6.4875 79.5316C-9.23887 78.0374 17.3247 56.3037 19.4093 55.4499C21.5143 54.6065 51.0038 70.1041 52.7009 72.5974C54.3776 75.0802 49.0787 102.832 48.182 104.324C47.2749 105.835 9.38122 88.1421 -6.4875 79.5316Z"
              fill="#A69CE7"
              opacity="0.2"
          />
          <path
              d="M82.43 103.742C79.4242 108.979 51.6429 106.132 49.9163 105.243C48.1898 104.354 55.1074 74.557 56.0761 73.0256C57.0552 71.4739 72.924 70.7035 75.3466 71.4887C77.749 72.2635 82.43 103.742 82.43 103.742Z"
              fill="#635EC0"
              opacity="0.4"
          />
          <path
              d="M-6.49424 79.5312C-9.45675 77.7483 -4.75865 126.558 -3.36747 128.997C-1.99661 131.425 45.2778 109.05 46.7161 106.707C48.1648 104.343 25.9622 98.9986 -6.49424 79.5312Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M-6.4883 79.5299C-8.16491 79.5916 -5.99221 108.905 -4.75418 113.038C-3.53586 117.109 19.2221 105.957 28.7939 107.932C38.4776 109.938 45.1422 108.925 45.9945 106.023C46.8677 103.08 32.9609 99.5921 18.0919 92.833C3.29423 86.085 -4.77106 79.4892 -6.4883 79.5299Z"
              fill="#905EC0"
              opacity="0.2"
          />
          <path
              d="M73.0054 108.267C73.2921 105.665 48.3841 106.071 44.8759 108.042C41.4089 109.983 3.0781 129.614 2.3512 129.728C1.61384 129.862 47.2924 118.719 73.0054 108.267Z"
              fill="#4068FF"
          />
          <path
              d="M-28.2739 52.0172C-26.292 51.2132 -9.83999 76.804 -8.55811 83.247C-7.31503 89.5157 -5.23228 127.187 -5.23228 127.187C-5.23228 127.187 -24.6765 78.4145 -28.2739 52.0172Z"
              fill="#81F2FF"
          />
          <path
              d="M3.56139 31.9203C3.56139 31.9203 12.185 49.0583 17.5831 52.25C22.9812 55.4416 46.2708 69.8533 52.7366 70.922C59.2536 71.9913 87.8403 69.0333 44.5372 45.6754C24.9926 35.1202 3.56139 31.9203 3.56139 31.9203Z"
              fill="#DBF2FF"
          />
          <path
              d="M-28.6331 48.1803C-28.4901 50.7469 -11.9398 74.1009 -8.65716 76.4599C-5.39487 78.8085 15.9554 56.3685 16.5246 54.9141C17.0735 53.4491 8.0108 41.405 6.89358 39.9814C5.78682 38.5375 14.7382 51.3467 13.4435 56.2056C12.1703 60.9727 -5.10842 72.8135 -10.6198 69.692C-16.1515 66.5601 -28.6331 48.1803 -28.6331 48.1803Z"
              fill="#FFF2FF"
              opacity="0.5"
          />
          <path
              d="M8.51758 40.8178C8.51758 40.8178 19.2508 43.9303 29.9272 51.7939C40.5228 59.5645 37.24 63.1937 37.24 63.1937C37.24 63.1937 21.2704 55.328 17.2227 52.0095C13.1448 48.6497 8.51758 40.8178 8.51758 40.8178Z"
              fill="white"
          />
          <path
              d="M51.623 70.653C51.959 69.9009 57.3595 71.1918 58.0009 72.2418C58.6325 73.2611 55.5214 77.0558 54.0355 76.5475C52.5398 76.0084 51.623 70.653 51.623 70.653Z"
              fill="#DFE5FF"
          />
          <path
              d="M15.4159 50.1282C13.285 48.8764 13.2751 58.2264 13.9965 58.5723C14.7179 58.9182 24.2032 57.0188 23.9249 56.3614C23.6368 55.6733 15.4159 50.1282 15.4159 50.1282Z"
              fill="#DFE5FF"
          />
          <path
              d="M21.3299 123.857C17.2404 122.316 39.8673 111.019 46.7362 110.008C53.738 108.988 47.81 116.755 44.3295 118.124C40.8797 119.482 21.3299 123.857 21.3299 123.857Z"
              fill="#FFF2FF"
          />
          <path
              d="M44.5526 105.281C44.1567 103.355 49.1699 102.853 49.6691 102.982C50.1787 103.09 53.9833 106.232 54.0701 106.662C54.1674 107.072 46.1093 107.548 45.4187 107.192C44.7182 106.806 44.5526 105.281 44.5526 105.281Z"
              fill="#DDE3FF"
          />
          <path
              d="M-27.6871 50.3672C-29.6894 49.4644 -20.0317 86.899 -16.6485 93.6533C-13.325 100.274 -6.76508 89.2346 -8.22671 82.4421C-9.71726 75.5061 -27.6871 50.3672 -27.6871 50.3672Z"
              fill="#72ACEF"
              opacity="0.4"
          />
          <path
              d="M-27.4103 46.7028C-28.9785 48.7993 -13.7648 74.5489 -9.53751 75.6726C-5.28989 76.8067 14.0073 58.5522 14.0073 58.5522C14.0073 58.5522 -5.86102 77.5662 -6.16932 78.564C-6.31825 79.0527 6.10358 86.2218 18.6682 92.565C31.5171 99.0546 44.6005 104.74 44.6005 104.74C44.6005 104.74 18.0066 94.8972 -6.47763 79.5617C-6.47763 79.5617 -7.00843 85.4822 -6.82024 92.8011C-6.82024 92.8011 -8.85704 81.1375 -11.4172 76.4777C-14.0174 71.746 -27.3121 52.1505 -27.9437 51.1313C-28.595 50.0505 -27.4103 46.7028 -27.4103 46.7028Z"
              fill="#C7D1FF"
          />
          <path
              d="M53.13 75.593C52.9049 77.3275 50.4712 97.0716 50.4712 97.0716L54.8984 76.401L53.13 75.593Z"
              fill="#C7D1FF"
          />
          <path
              d="M9.55988 61.0439C13.6503 57.3937 11.0277 48.564 5.61576 41.4073C0.113357 34.1269 -14.9605 58.9515 -11.4997 66.0542C-8.10849 72.9925 9.55988 61.0439 9.55988 61.0439Z"
              fill="#8DFFFF"
          />
          <path
              d="M56.1642 76.2856C65.0523 71.3543 72.7758 94.531 73.6546 102.206C74.5156 109.665 55.371 107.515 52.7425 103.437C50.0839 99.3173 56.1642 76.2856 56.1642 76.2856Z"
              fill="#8DCAFF"
          />
          <path
              d="M11.1354 61.7768C0.350555 69.7919 19.8808 67.9005 29.2455 75.217C38.519 82.4608 42.5461 99.4415 46.1394 100.598C49.7531 101.766 52.0762 75.8993 50.3871 72.7419C48.6881 69.5538 27.6362 59.4301 24.2761 58.4191C20.9665 57.4597 15.3114 58.6591 11.1354 61.7768Z"
              fill="#959CC0"
              opacity="0.2"
          />
          <path
              d="M80.6503 100.363C80.2415 99.5096 75.556 104.215 70.6063 105.393C65.7488 106.54 54.047 106.655 54.047 106.655C54.047 106.655 68.7353 108.027 72.7754 106.87C76.9077 105.683 80.6503 100.363 80.6503 100.363Z"
              fill="#DDE3FF"
          />
          <path
              d="M-28.2734 52.0176C-28.2734 52.0176 -10.8257 75.5254 -8.78906 83.7964C-6.83127 91.8211 -12.358 98.5095 -15.7995 94.9121C-19.2816 91.2938 -28.2734 52.0176 -28.2734 52.0176Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M-6.5452 80.4009C-7.41678 79.7978 -7.74345 101.938 -4.66509 105.991C-1.62674 109.972 11.3016 107.276 23.0441 107.181C35.1036 107.07 35.9532 111.209 39.526 108.963C43.1506 106.666 44.5464 105.303 44.5464 105.303C44.5464 105.303 17.0306 96.5021 -6.5452 80.4009Z"
              fill="#905EC0"
              opacity="0.4"
          />
          <path
              d="M46.1001 107.261C45.4064 107.16 37.1824 112.1 37.6478 112.493C38.1131 112.887 46.7588 109.425 49.102 110.015C51.4656 110.615 49.3412 115.638 50.0287 115.401C50.7162 115.164 69.1506 108.365 70.0849 107.998C71.0296 107.61 55.4045 106.81 52.5105 106.99C49.6472 107.16 46.1001 107.261 46.1001 107.261Z"
              fill="#3340C0"
              opacity="0.4"
          />
          <path
              d="M82.1648 99.4678C82.1543 99.4881 77.7515 78.0475 76.6058 73.8849C75.4811 69.6818 67.1058 57.4006 47.4714 47.5188C27.8167 37.6265 10.8348 34.0712 4.72356 34.1104C-1.14275 34.1729 -19.9894 42.122 -21.109 42.5991C-12.2418 37.7084 1.7364 31.8265 3.55459 31.8891C4.93331 31.9567 34.1773 38.9494 43.4396 43.7202C52.3973 48.334 73.6903 65.6239 75.4926 67.0148C78.1652 69.111 80.9016 87.2004 81.9572 97.1763L81.9671 97.2071C82.0287 98.0355 82.1118 98.7723 82.1648 99.4678Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <path
              d="M-9.20862 99.3865C-5.59328 97.8561 -5.40179 118.541 -5.94772 118.903C-6.51395 119.253 -13.9848 101.424 -9.20862 99.3865Z"
              fill="white"
          />
          <path
              d="M-3.28741 129.989C-8.1494 127.253 -30.0899 52.291 -28.6511 48.2003C-28.2597 47.0911 -25.8477 45.3522 -22.5136 43.4199C-22.7026 43.8366 -26.5187 52.7425 -26.6102 56.1136C-26.6679 58.3713 -19.6523 80.0782 -11.7925 108.928C-9.03193 119.037 -4.51575 123.342 -1.41473 125.505C1.55456 127.574 10.2928 128.322 21.3315 123.856C47.6502 113.176 68.4458 108.878 73.265 106.657C80.8693 103.121 81.8857 97.6043 81.9515 97.227C82.2903 100.511 82.4757 102.946 82.4351 103.773C82.2334 106.959 1.57459 132.724 -3.28741 129.989Z"
              fill="#5E2E84"
              opacity="0.5"
          />
          <defs>
            <linearGradient
                id="paint0_linear_3071_26224"
                gradientUnits="userSpaceOnUse"
                x1="-1.42392"
                x2="43.4848"
                y1="130.939"
                y2="43.7497"
            >
              <stop
                  stop-color="#B8FFFF"
              />
              <stop
                  offset="1"
                  stop-color="#6481FF"
              />
            </linearGradient>
          </defs>
        </svg>

        <div v-if="$store.getters.getGamesGemUnlockDiscount[clickedGame]>0" class="c-header-signup-offer-wrap unlock-discount">
          <div class="c-header-signup-offer-badge">
            <p class="c-header-signup-offer-badge-text">
              {{ $store.getters.getGamesGemUnlockDiscount[clickedGame] }}%<br />Discount
            </p>
            <!--This week! todo:discount duration-->
          </div>
          <svg
              width="74"
              height="74"
              style="position: absolute; top: -16px; transform: rotate(33deg);"
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M54.8582 48.0766C53.6066 50.0708 21.562 57.803 19.8667 57.1137C19.407 56.9263 18.7228 55.8743 17.9757 54.4297C17.9673 54.4249 17.9673 54.4249 17.9673 54.4249C17.8749 54.2379 17.7741 54.0461 17.6781 53.8459C17.6781 53.8459 17.6745 53.8327 17.671 53.8195C15.7887 49.9826 13.592 43.971 13.6552 43.2058C13.6985 42.6294 17.2381 30.443 19.4366 26.635C21.5629 22.9522 29.2824 14.3259 29.9052 13.5943C30.843 12.51 38.524 11.7225 42.7508 11.4794L42.7639 11.4759C43.1109 11.4535 43.4315 11.4382 43.7258 11.4299L43.7341 11.4348C44.6266 11.3933 45.2797 11.3806 45.5367 11.4177C46.8804 11.5588 56.1098 46.0823 54.8582 48.0766Z"
                fill="url(#paint0_linear_3071_26179)"
            />
            <path
                opacity="0.2"
                d="M20.3653 55.1806C22.1687 56.5892 43.6366 53.4064 49.927 44.4783C53.1577 39.8855 47.6227 32.2695 45.8183 28.934C43.9475 25.4822 36.0586 37.5069 34.1618 39.8858C32.2554 42.2814 38.9915 43.9213 41.1536 44.535C43.256 45.1364 46.3626 41.8192 46.8361 40.7676C47.318 39.7208 43.3887 33.0651 41.3507 34.9171C39.2983 36.7942 41.6124 39.266 39.5638 39.4972C37.4804 39.7308 40.5429 31.5335 43.8886 32.0064C47.1314 32.4646 49.6813 38.1345 46.1123 42.9663C42.5156 47.8266 35.9689 47.7101 31.4294 40.5686C26.4942 32.8201 39.1483 29.6482 44.9372 26.6994C50.5211 23.8549 43.1673 14.2979 39.7069 12.7121C36.1082 11.0575 25.8092 17.1701 20.0272 26.6256C14.2391 36.1109 12.1495 48.7559 20.3653 55.1806Z"
                fill="#B3B0FF"
            />
            <path
                d="M13.6583 43.2053C13.2314 45.0633 21.5753 38.8462 22.5121 37.7058C23.4441 36.5737 30.2116 25.2376 31.0947 23.2645C31.9827 21.2829 30.8691 13.4916 30.3809 13.2766C29.901 13.0663 20.49 24.1789 19.2841 26.9232C18.0878 29.6509 13.9816 41.7774 13.6583 43.2053Z"
                fill="white"
            />
            <path
                d="M20.0464 56.4438C21.1507 56.9033 32.2376 50.043 32.6924 49.4482C33.1472 48.8534 23.0421 37.1958 22.51 37.7125C21.9778 38.2293 14.9868 43.0673 14.4976 43.876C14.0001 44.68 17.7363 55.4887 20.0464 56.4438Z"
                fill="#8DE1FF"
            />
            <path
                opacity="0.2"
                d="M33.5259 48.4069C32.8403 49.5365 24.2159 37.8987 23.8982 37.0026C23.5852 36.0982 30.7151 23.9803 31.8005 23.3154C32.8811 22.6588 44.4728 25.4542 45.0834 25.8623C45.7023 26.2753 37.4768 41.8916 33.5259 48.4069Z"
                fill="#A69CE7"
            />
            <path
                opacity="0.4"
                d="M45.5292 11.4124C47.676 12.7855 45.9137 24.4392 45.5039 25.1491C45.0941 25.8589 32.6731 22.3398 32.0471 21.9005C31.4128 21.4563 31.4092 14.7512 31.7892 13.7458C32.1644 12.7487 45.5292 11.4124 45.5292 11.4124Z"
                fill="#635EC0"
            />
            <path
                opacity="0.4"
                d="M33.5295 48.4057C32.718 49.6185 53.3892 48.6257 54.4453 48.0886C55.4966 47.5598 47.0212 27.1783 46.0624 26.5245C45.0954 25.866 42.3929 35.1175 33.5295 48.4057Z"
                fill="#3340C0"
            />
            <path
                opacity="0.2"
                d="M33.5217 48.4063C33.5138 49.1144 45.9148 48.7916 47.6821 48.3534C49.4231 47.9222 45.1825 38.1028 46.2086 34.1077C47.2504 30.0661 46.958 27.2361 45.7519 26.8181C44.529 26.3904 42.7773 32.1823 39.6271 38.3136C36.483 44.4151 33.5393 47.6816 33.5217 48.4063Z"
                fill="#905EC0"
            />
            <path
                d="M47.2489 15.4787C46.1577 15.3052 45.8251 25.8135 46.585 27.3323C47.333 28.8331 54.8327 45.3889 54.866 45.6976C54.9077 46.0112 51.1348 26.5297 47.2489 15.4787Z"
                fill="#4068FF"
            />
            <path
                d="M21.4893 57.0332C21.1905 56.1815 32.3114 49.7639 35.0534 49.3539C37.7211 48.9567 53.6436 48.841 53.6436 48.841C53.6436 48.841 32.69 56.0509 21.4893 57.0332Z"
                fill="#81F2FF"
            />
            <path
                d="M13.6533 43.2053C13.6533 43.2053 21.0524 39.9168 22.5071 37.7058C23.9618 35.4947 30.5084 25.9686 31.0898 23.2645C31.6724 20.5388 31.0039 8.42815 20.281 26.2101C15.4359 34.2356 13.6533 43.2053 13.6533 43.2053Z"
                fill="#DBF2FF"
            />
            <path
                opacity="0.5"
                d="M19.8652 57.1099C20.95 57.1016 31.13 50.5973 32.1908 49.2613C33.2469 47.9335 24.2193 38.4792 23.6176 38.2098C23.0112 37.9487 17.7506 41.5254 17.1278 41.9676C16.4968 42.4049 22.0777 38.8906 24.0997 39.5347C26.0836 40.1679 30.7255 47.6915 29.2981 49.9517C27.8659 52.2202 19.8652 57.1099 19.8652 57.1099Z"
                fill="#FFF2FF"
            />
            <path
                d="M17.5101 41.2981C17.5101 41.2981 19.0394 36.8364 22.5704 32.4949C26.0606 28.1854 27.524 29.6428 27.524 29.6428C27.524 29.6428 23.885 36.2157 22.4042 37.8549C20.9054 39.5059 17.5101 41.2981 17.5101 41.2981Z"
                fill="white"
            />
            <path
                d="M30.9567 23.7327C30.6464 23.5758 31.2999 21.3253 31.7556 21.0762C32.198 20.8306 33.7347 22.2189 33.4904 22.8349C33.2329 23.4545 30.9567 23.7327 30.9567 23.7327Z"
                fill="#DFE5FF"
            />
            <path
                d="M21.5748 38.5766C21.004 39.4495 24.9453 39.6429 25.1057 39.3458C25.2661 39.0487 24.6573 35.0117 24.3746 35.1157C24.0787 35.2232 21.5748 38.5766 21.5748 38.5766Z"
                fill="#DFE5FF"
            />
            <path
                d="M52.7692 37.5762C52.0368 39.269 47.7326 29.5019 47.4454 26.5858C47.1572 23.6135 50.3114 26.2697 50.8178 27.7646C51.3207 29.2464 52.7692 37.5762 52.7692 37.5762Z"
                fill="#FFF2FF"
            />
            <path
                d="M45.4131 27.4106C44.5932 27.5386 44.4831 25.4151 44.5474 25.2072C44.6033 24.9946 46.0048 23.4543 46.1879 23.4264C46.3627 23.3937 46.4002 26.8003 46.2362 27.0842C46.0591 27.3717 45.4131 27.4106 45.4131 27.4106Z"
                fill="#DDE3FF"
            />
            <path
                opacity="0.4"
                d="M20.8013 56.7505C20.3802 57.5763 36.3564 54.2626 39.2722 52.973C42.1304 51.706 37.6095 48.7172 34.7165 49.1959C31.7624 49.6839 20.8013 56.7505 20.8013 56.7505Z"
                fill="#72ACEF"
            />
            <path
                d="M19.2624 56.5642C20.1144 57.2677 31.2772 51.3754 31.8364 49.6161C32.4004 47.8484 25.0957 39.3442 25.0957 39.3442C25.0957 39.3442 32.7091 48.1045 33.1234 48.2547C33.3264 48.3274 36.6 43.2359 39.5282 38.0676C42.524 32.7824 45.1854 27.3821 45.1854 27.3821C45.1854 27.3821 40.498 38.3937 33.5378 48.4048C33.5378 48.4048 36.0229 48.7484 39.112 48.8172C39.112 48.8172 34.1539 49.4398 32.1378 50.4247C30.0905 51.4251 21.5609 56.633 21.1184 56.8787C20.6497 57.1313 19.2624 56.5642 19.2624 56.5642Z"
                fill="#C7D1FF"
            />
            <path
                d="M33.0725 23.1931C33.7991 23.3231 42.0731 24.7486 42.0731 24.7486L33.4489 22.464L33.0725 23.1931Z"
                fill="#C7D1FF"
            />
            <path
                d="M26.0601 41.2667C24.6041 39.4686 20.8288 40.3954 17.7023 42.532C14.5219 44.7043 24.6818 51.5611 27.746 50.2459C30.7395 48.9567 26.0601 41.2667 26.0601 41.2667Z"
                fill="#8DFFFF"
            />
            <path
                d="M33.4198 21.9293C31.5209 18.0827 41.4474 15.2958 44.7005 15.0807C47.8626 14.8687 46.5686 22.8957 44.7963 23.9212C43.0059 24.9586 33.4198 21.9293 33.4198 21.9293Z"
                fill="#8DCAFF"
            />
            <path
                opacity="0.2"
                d="M26.3977 40.6177C29.5582 45.3263 29.1561 37.055 32.4299 33.2553C35.6712 29.4926 42.911 28.1386 43.4714 26.6472C44.0367 25.1474 33.1796 23.6447 31.8144 24.2929C30.436 24.9446 25.7423 33.6142 25.2481 35.0102C24.7767 36.386 25.1679 38.7942 26.3977 40.6177Z"
                fill="#959CC0"
            />
            <path
                d="M44.0678 12.0924C43.6999 12.2474 45.5888 14.3179 45.9849 16.4283C46.3704 18.4992 46.1822 23.4345 46.1822 23.4345C46.1822 23.4345 47.0576 17.2703 46.6515 15.5438C46.2347 13.7777 44.0678 12.0924 44.0678 12.0924Z"
                fill="#DDE3FF"
            />
            <path
                d="M17.6743 53.8547C17.6743 53.8547 20.5849 55.602 21.6643 55.7575C22.7269 55.9033 27.9425 52.7905 27.9425 52.7905C27.9425 52.7905 23.1 54.5822 20.7538 53.9402C18.3479 53.286 16.9385 52.2942 16.9385 52.2942L17.6743 53.8547Z"
                fill="#DDE3FF"
            />
            <path
                opacity="0.4"
                d="M21.4844 57.0332C21.4844 57.0332 31.7473 50.1537 35.2752 49.4625C38.6977 48.7996 41.4053 51.2648 39.8192 52.6428C38.2234 54.0374 21.4844 57.0332 21.4844 57.0332Z"
                fill="#905EC0"
            />
            <path
                opacity="0.4"
                d="M33.8898 48.4496C33.6179 48.8048 42.9446 49.3905 44.7154 48.1749C46.455 46.9746 45.5801 41.47 45.778 36.518C45.9752 31.432 47.7371 31.1576 46.8625 29.606C45.9676 28.0316 45.4214 27.4156 45.4214 27.4156C45.4214 27.4156 41.1544 38.8369 33.8898 48.4496Z"
                fill="#905EC0"
            />
            <path
                opacity="0.4"
                d="M46.2791 26.7988C46.2228 27.0891 48.1386 30.656 48.3141 30.4678C48.4895 30.2796 47.2047 26.5649 47.5009 25.589C47.8019 24.6048 49.8762 25.602 49.7902 25.3073C49.7042 25.0127 47.211 17.104 47.0752 16.7028C46.9311 16.2967 46.2775 22.8673 46.2948 24.0909C46.3085 25.3014 46.2791 26.7988 46.2791 26.7988Z"
                fill="#3340C0"
            />
            <path
                opacity="0.5"
                d="M43.7206 11.4369C43.7289 11.4417 34.6014 12.8639 32.8235 13.2626C31.0289 13.6517 25.6822 16.9338 21.1192 25.0109C16.5513 33.0962 14.7089 40.183 14.6017 42.7601C14.5094 45.2343 17.479 53.3401 17.6574 53.8217C15.7752 49.9847 13.5785 43.9731 13.6417 43.2079C13.6981 42.6281 17.2377 30.4416 19.4362 26.6336C21.5625 22.9508 29.282 14.3245 29.9048 13.5929C30.8426 12.5087 38.5236 11.7212 42.7504 11.4781L42.7635 11.4745C43.114 11.4653 43.4263 11.4452 43.7206 11.4369Z"
                fill="#5E2E84"
            />
            <path
                d="M32.2184 49.8209C32.114 50.696 34.6748 49.3463 34.9663 49.1693C35.2577 48.9924 33.2547 48.1032 32.8515 48.1265C32.4448 48.1367 32.2184 49.8209 32.2184 49.8209Z"
                fill="white"
            />
            <path
                d="M41.8417 49.9548C41.2697 48.3998 49.9935 48.7376 50.1347 48.9751C50.2712 49.2209 42.604 52.0095 41.8417 49.9548Z"
                fill="white"
            />
            <path
                opacity="0.5"
                d="M54.8638 48.0769C53.6122 50.0712 21.5676 57.8033 19.8722 57.114C19.4126 56.9266 18.7283 55.8746 17.9812 54.43C18.1531 54.5181 21.8302 56.307 23.2494 56.4138C24.2 56.4838 33.4926 53.9657 45.8134 51.2361C50.1307 50.2769 52.037 48.4602 53.0114 47.1967C53.9437 45.9869 54.4361 42.3184 52.7767 37.5746C48.8071 26.2637 47.4161 17.4102 46.5773 15.3337C45.2404 12.0566 42.9356 11.5164 42.7778 11.481C44.1692 11.4047 45.1991 11.3758 45.547 11.4097C46.886 11.5591 56.1153 46.0827 54.8638 48.0769Z"
                fill="#5E2E84"
            />
            <defs>
              <linearGradient
                  id="paint0_linear_3071_26179"
                  x1="55.2958"
                  y1="47.3125"
                  x2="19.4495"
                  y2="26.6167"
                  gradientUnits="userSpaceOnUse"
              >
                <stop
                    stop-color="#B8FFFF"
                />
                <stop
                    offset="1"
                    stop-color="#6481FF"
                />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </transition>
    <!--signup popup--><signup-popup :open="signUpPopup" @closeSignUpPopup="signUpPopup=false" />
  </section>
</template>

<script>
import AppCard from "../shared/app-card";

import { allGames, crashGames, scratchCards, instantGames, spincity, casinoGames } from "../../constants/index";
import signupPopup from "../popups/signup-popup";
import Flicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking.css";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import img_slot_battle from "../../assets/images/slot-battle.webp";
import {botUri} from "../../constants/index";

export default {
  name: "AllGames",
  components: { AppCard,
    signupPopup,
    Flicking,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      allGames,
      scratchCards,
      crashGames,
      instantGames,
      spincity,
      casinoGames,
      unlockGamePopup: false,
      clickedGame:"",
      notEnoughGems:false,
      signUpPopup:false,
      isTest: false,
      page:"slot",
      isJackbotPage: false,
      isMobile : window.innerWidth <= 900,
      plugins: [
        new AutoPlay({
          duration: 5000,
          direction: "NEXT",
          stopOnHover: false
        }),
        new Pagination({
          type: 'bullet'
        })],
      img_slot_battle:img_slot_battle,
    };
  },
  computed: {
    games() {
      let myGames = this.page==="slot"||this.isJackbotPage?this.allGames:
          this.page==="scratch"?this.scratchCards:
              this.page==="crash"?this.crashGames:
                  this.page==="spincity"?this.spincity:
                      this.page==="instant"?this.instantGames:
                          this.page==="casino"?this.casinoGames:null
      ;
      myGames = { ...myGames };

      if(!this.isTest) {
        for (let gameName of Object.keys(myGames)) {
          if (myGames[gameName].testing && !myGames[gameName].isSoon ) {
            delete myGames[gameName];
          }
        }
      }

      return myGames;
    },
  },
  mounted() {
    let url = window.location.hostname;
    if(url.includes("test.") || url.includes("localhost")) {
      this.isTest = true;
    }

    this.page=this.$store.getters.getSelectedGamesType;
    this.isJackbotPage = window.location.pathname.includes("jackbot");
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 900;
    });
    // let self = this
    // setInterval(()=>{
    //   self.$refs.flicking.next();
    // }, 5000)

  },
  updated() {
    let css = '.flicking-pagination {\n' +
            '  position: absolute;\n' +
            '  left: 0;\n' +
            '  bottom: 10px;\n' +
            '  width: 100%;\n' +
            '  text-align: center;\n' +
            '  z-index: 2;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-bullets,\n' +
            '.flicking-pagination-scroll {\n' +
            '  font-size: 0;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll {\n' +
            '  left: 50%;\n' +
            '  -webkit-transform: translate(-50%);\n' +
            '      -ms-transform: translate(-50%);\n' +
            '          transform: translate(-50%);\n' +
            '  white-space: nowrap;\n' +
            '  overflow: hidden;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll .flicking-pagination-slider {\n' +
            '  -webkit-transition: .2s transform;\n' +
            '  transition: .2s transform;\n' +
            '}\n' +
            '.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-slider,\n' +
            '.flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-bullet {\n' +
            '  -webkit-transition: none;\n' +
            '  transition: none;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-bullet {\n' +
            '  display: inline-block;\n' +
            '  width: 8px;\n' +
            '  height: 8px;\n' +
            '  margin: 0 4px;\n' +
            '  border-radius: 50%;\n' +
            '  background-color: rgb(10 10 10 / 10%);\n' +
            '  cursor: pointer;\n' +
            '  font-size: 1rem;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet {\n' +
            '  vertical-align: middle;\n' +
            '  position: relative;\n' +
            '  -webkit-transition: .2s transform;\n' +
            '  transition: .2s transform;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-bullet-active {\n' +
            '  background-color: #ff585c;\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet {\n' +
            '  vertical-align: middle;\n' +
            '  position: relative;\n' +
            '  -webkit-transition: .2s transform,.2s left;\n' +
            '  transition: .2s transform,.2s left;\n' +
            '  -webkit-transform: scale(0);\n' +
            '      -ms-transform: scale(0);\n' +
            '          transform: scale(0);\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet-active {\n' +
            '  -webkit-transform: scale(1);\n' +
            '      -ms-transform: scale(1);\n' +
            '          transform: scale(1);\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet-prev,\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet-next {\n' +
            '  -webkit-transform: scale(0.66);\n' +
            '      -ms-transform: scale(0.66);\n' +
            '          transform: scale(0.66);\n' +
            '}\n' +
            '\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet-prev2,\n' +
            '.flicking-pagination-scroll .flicking-pagination-bullet-next2 {\n' +
            '  -webkit-transform: scale(0.33);\n' +
            '      -ms-transform: scale(0.33);\n' +
            '          transform: scale(0.33);\n' +
            '}',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  } ,
  methods:{
    /**
     * Checks if game name is locked
     * @param {String} name game name
     * @return {Boolean}
     */
    isGameLocked(name){
      if(name==="tycoon")return false
      return /*this.isTest&&this.page==="scratch"?false:*/this.$store.getters.getUsername?!this.$store.getters.getUnlockedGames.includes(name):false;
    },

    /**
     * Open game page if game unlocked, opens unlock popup if not
     * @param {String} name game name
     */
    openGame(name){
      if(this.games[name].isSoon && !(
          location.hostname.includes("localhost") ||
          location.hostname.includes("test."))
      )
        return;
      if(!this.$store.getters.getUsername){
        this.signUpPopup=true;
        return;
      }
      if(this.isGameLocked(name)){
        this.notEnoughGems = false;
        this.clickedGame=name;
        this.unlockGamePopup = true;
      } else {
        this.$router.push({ path: `/${this.page==='scratch'?'scratch':
              this.page==='crash'?'crash':
                  this.page==='instant'?'instant':
                      this.page==='spincity'?'spincity':
                          this.page==='casino'?'casino':
                            'game'}/${name}`, query: this.$route.query })
      }
    },

    gameUnlockPriceWithDiscount(name) {
      console.log(this.$store.getters.getGamesGemUnlockDiscount[name]);
      return Math.round(this.$store.getters.getGamesGemUnlockPrice[name] * (100-this.$store.getters.getGamesGemUnlockDiscount[name])/100);
    },

    /**
     * unlocks game if player has enough gems
     * @param {String} name game name
     */
    async unlockGame(name) {
      if(this.notEnoughGems) {
        this.notEnoughGems = false;
        this.unlockGamePopup = false;
        return;
      }
      if(this.$store.getters.getGemsBalance >= this.gameUnlockPriceWithDiscount(name)) {
        const unlock = await this.$store.dispatch("unlockGame", { game: name, price: this.gameUnlockPriceWithDiscount(name) });
        if(unlock.success) {
          this.unlockGamePopup=false;
          this.openGame(name);
        }
      } else {
        this.notEnoughGems=true;
      }
    },
    getGames(){
      const gamesNew=[];
      const gamesOpen=[];
      const gamesLocked=[];
      const gamesSoon=[];
      Object.keys(this.games).forEach(game => {
        if(this.games[game].isNew && !this.games[game].isSoon) {
          gamesNew.push(this.games[game])
          return;
        }
        if(this.games[game].isSoon) {
          gamesSoon.push(this.games[game])
          return;
        }
        if(this.isGameLocked(game) && !this.games[game].isNew) {
          gamesLocked.push(this.games[game]);
        }
        else{
          gamesOpen.push(this.games[game]);
        }
      })
      gamesNew.sort((a, b) => a.order - b.order).reverse();
      gamesOpen.sort((a, b) => a.order - b.order).reverse();
      gamesLocked.sort((a, b) => a.order - b.order).reverse();
      gamesSoon.sort((a, b) => a.order - b.order).reverse();
      const output = [];
      gamesNew.forEach(game =>{output.push(game);})
      gamesOpen.forEach(game =>{output.push(game);})
      gamesLocked.forEach(game =>{output.push(game);})
      gamesSoon.forEach(game =>{output.push(game);})
      let gamesCount = this.isJackbotPage&&this.isMobile?6:100
      gamesCount=Math.min(gamesCount,output.length);
      const ret=output.splice(0,gamesCount);
      return ret;
    },
    setSelectedGamesPage(type){
      if (type==='battle'){
        if(!this.$store.getters.getUsername){
          this.signUpPopup=true;
          return;
        }
        //this.$store.commit("setSelectedGamesType", type);
        this.$router.push({ path: '/slotbattle', query: this.$route.query })
      }
      else if (type==='poker'){
        if(!this.$store.getters.getUsername){
          this.signUpPopup=true;
          return;
        }
        //this.$store.commit("setSelectedGamesType", type);
        window.open("https://poker.blockspingaming.com")
      }
      else {
        this.page = type;
        this.$store.commit("setSelectedGamesType", type);
      }
    },
    openTelegramBot() {
      window.open(botUri);
    },
    clickTopps(){
      const state=this.$store.state;
      state.prizesCategory="weekly";
      state.rafflePrizesPopup=true;
    },
    openSpincityBlog() {
      window.open("https://blog.blockspingaming.com/?p=4287");
    },
    openLink(url) {
      window.open(url);
    },
  }
};
</script>
<style lang="scss" scoped>
h2 {
  /* Our games */


  font-family: 'Rubik',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  /* or 48px */
  letter-spacing: -0.03em;
  text-transform: uppercase;

  background: linear-gradient(281.53deg, #7A00FF 5.6%, #00FFF0 111.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


  /* Inside auto layout */
  flex: none;
  flex-grow: 0;

}
.l-home__games-grid {
  display: flex;
  flex-wrap: wrap;
}

.l-home__games-grid-item {
  position: relative;
  padding: 0 10px;
  flex: 1 1 50%;
  max-width: 50%;
  margin-bottom: 20px;
  height: 100%;

  @include media-min($screen-tablet-landscape) {
    flex: 1 1 25%;
    width: 25%;
    max-width: 25%;
  }
}
.l-home__games-grid-item-crash {
  position: relative;
  padding: 0 10px;
  max-width: unset;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
  @include media-min($screen-tablet-landscape) {
    width: 50%;
  }
}

.unlock-discount{
  transform: rotate(331deg) translate(-9px, -64px);

  @include media-max(600px) {
    transform: translate(47px, -63px) rotate(349deg);
  }
}
.l-home__games-header{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.l-home__games-categories{
  display: flex;
  font-family: Rubik,sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #031223;
  margin-bottom: 20px;
  flex-wrap: wrap;
  row-gap: 30px;
}
.l-home__games-categories-button{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  img{
    height: 35px;
  }

  .soon{
    position: absolute;
    right: 0;
    font-size: 12px;
    color: #FF2E30;
    bottom: -13px;
  }
  .new{
    position: absolute;
    right: 0;
    font-size: 12px;
    bottom: -13px;
    transition: $transition-medium;

    background: linear-gradient(93.92deg, #00C6FB 0.27%, #005BEA 105.74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .nft{
    position:absolute;
    top: -57px;
    left: 39%;

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    text-align: right;
    letter-spacing: -0.03em;

    /* gradient/yellow */

    background: linear-gradient(95.11deg, #FFC83C -9.98%, #DC8400 118.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover{
    .new{
      opacity: 0;
    }
    .l-home__games-categories-button-unselected{
      padding: 5px 15px;
      background: rgba(44, 44, 44, 0.15);
      border-radius: 10px;
      box-shadow: 0 0 0 rgb(253 134 81 / 0%);
    }
  }
}
.l-home__games-categories-button-selected{
  padding: 5px 15px;
  transition: ease-in-out 0.3s;
  background: linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%);
  -webkit-box-shadow: 0 0 10px rgb(253 134 81 / 80%);
  box-shadow: 0 0 10px rgb(253 134 81 / 80%);
  border-radius: 10px;
  color: white;
}
.l-home__games-categories-button-unselected{
  transition: ease-in-out 0.3s;
}
.l-home__games-categories-button-soon{
  display: flex;
}
.l-home__games-categories-button-unselected{
  display: flex;
}
.main-banner{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px;
  cursor: pointer;
}
.panel{
  width: 100%;
  margin-bottom: 30px;
}

.flicking-pagination {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.flicking-pagination-bullet-active {
  background-color: #ff585c;
}

.flicking-pagination-bullets, .flicking-pagination-scroll {
  font-size: 0;
}

.flicking-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgb(10 10 10 / 10%);
  cursor: pointer;
  font-size: 1rem;
}
</style>
